import React, {useEffect} from "react"
import "./index.scss"

const IndustryTemplate = ({children}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="industry-template">
            {children}
        </div>  
    )
}

export default IndustryTemplate
