import React from "react"
import BaseIcon from "./BaseIcon"

const ArrowUp = ({ color, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18 15L12 9L6 15" stroke={color ? color : "#B7B7B7"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default ArrowUp
