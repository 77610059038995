import React from "react"
import BaseIcon from "./BaseIcon"

const SwiperArrowRight = ({ ...props }) => (
    <BaseIcon
        {...props}
        width="20" height="20" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4.17188 10H15.8385" stroke="#0E0E0E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M10 4.16626L15.8333 9.99959L10 15.8329" stroke="#0E0E0E" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default SwiperArrowRight
