import React, { useEffect } from "react"
import {
    OUR_SERVICES,
} from "../../data/home/our-services"
import { OurCases } from "../../features/our-cases"
import { ServicesCards } from "../../features/services-cards"
import Clutch2019Icon from "../../components/icons/Clutch2019"
import Clutch2020Icon from "../../components/icons/Clutch2020"
import Clutch2021Icon from "../../components/icons/Clutch2021"
import Clutch2023Icon from "../../components/icons/Clutch2023"
import Clutch2024ShopifyIcon from "../../components/icons/ClutchShopify2024"
import Clutch2024GamblingIcon from "../../components/icons/ClutchGambling2024"
import Clutch2024Icon from "../../components/icons/Clutch2024"
import { VideoBlock } from "../../features/video-block"
import { CompaniesIcons } from "../../features/icons"
import { Gallery } from "../../features/gallery"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import GridLayout from "../../layouts/GridLayout"

import "./index.scss"
import { OurArticles } from "../../features/our-articles"
import { NAVIGATION_LINKS } from "../../constants/common"
import { OurTestimonials } from "../../features/our-testimonials"


const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Helmet defer={false}>
                <title>Newsoft - Software Development Company</title>
            </Helmet>
            <GridLayout>
                <div className="home">
                    <div className="home-intro">
                        <div className="home-intro-title">
                            <p className="t-s2">
                                <span className="t-s1-m">Software development</span>{" "}
                        company providing businesses with top-notch
                                <span className="t-s1-m"> tech talent</span> and helping
                        build powerful
                                <span className="t-s1-m"> software products</span>
                            </p>
                        </div>
                        <div className="home-intro-icons">
                            <CompaniesIcons/>
                            <div className="home-intro-icons-clutch">
                                <Clutch2019Icon width="90px" height="100px"/>
                                <Clutch2020Icon width="90px" height="100px"/>
                                <Clutch2021Icon width="90px" height="100px"/>
                                <Clutch2023Icon width="90px" height="100px"/>
                                <Clutch2024GamblingIcon width="90px" height="100px"/>
                                <Clutch2024Icon width="90px" height="100px"/>
                                <Clutch2024ShopifyIcon width="90px" height="100px"/>
                            </div>
                        </div>
                    </div>
                    <div className="home-services">
                        <ServicesCards
                            scrollHeader="Our services"
                            cardsArray={OUR_SERVICES}
                            link={NAVIGATION_LINKS.SERVISES}
                        />
                    </div>
                    <VideoBlock />
                    <div className="home-icons">
                        <CompaniesIcons />
                    </div>
                    <OurCases size={3}/>
                </div>
            </GridLayout>
            <OurTestimonials />
            <Gallery />
            <OurArticles />
            <BookACall/>
        </>
    )
}

export default Home
