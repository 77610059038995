import React, { useEffect } from "react"
import Footer from "../../toolkits/Footer"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga"
import "./index.scss"
import Header from "../../toolkits/Header"
import PrivacyMessage from "../../features/privacy-message"

const BasicLayout = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    },[location.pathname])

    return (
        <div className="basic-layout">
            <Header />
            <div className="basic-layout-content">
                {children}
                <PrivacyMessage />
                <Footer />
            </div>
        </div>
    )
}

export default BasicLayout
