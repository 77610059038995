import React from "react"
import { Route, Routes, Navigate } from "react-router-dom"

import { routes, ErrorRoute } from "./index"

const RenderComponent = ({ Component, props, Layout }) => {
    if (Layout !== null) {
        return (
            <Layout>
                <Component {...props} />
            </Layout>
        )
    } else {
        return (
            <Component {...props} />
        )
    }
}

const routeWithSubRoutes = (routes) =>
    routes.map(({ path, component: Component, layout: Layout }, index) => {
        return (
            <Route
                key={index}
                path={path}
                exact
                element={<RenderComponent Component={Component} Layout={Layout} />}
            />
        )
    })

const AppRoutes = () => (
    <Routes>
        {routeWithSubRoutes(routes)},
        <Route path="*" element={<Navigate replace to={ErrorRoute.path} />} />
    </Routes>
)

export default AppRoutes
