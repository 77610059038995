import React from "react"
import "./index.scss"

const GridLayout = ({ children }) => {
    return (
        <div className="grid-layout">
            {children}
        </div>
    )
}

export default GridLayout
