import React from "react"

import "./index.scss"


export const IndustryServices = ({header, services }) => {

    return (
        <div className="industry-template-services">
            <h2 className="t-h-m-22">{header}</h2>
            <div className="industry-template-services-items">
                {services.map((el, id) => (
                    <div className="industry-template-services-items-item" key={id}>
                        {el.icon}
                        <div className="industry-template-services-items-item-text">
                            <h3 className="t-h-m-20">{el.header}</h3>
                            <p className="t-s-14">{el.desc}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
