export const OUR_MANAGEMENT = [
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/volodymyr_tkach.webp",
        name: "Volodymyr Tkach",
        text: "CEO & Co-Founder",
        link: "https://www.linkedin.com/in/vova-tkach/",
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/oleh_roshka.webp",
        name: "Oleh Roshka",
        text: "Co-Founder",
        link: "https://www.linkedin.com/in/oleh-roshka/",
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/khrystyna_skop.webp",
        name: "Khrystyna Skop",
        text: "Head of Accounting and Finance",
        link: "https://www.linkedin.com/in/khrystyna-skop-a9a83bb8/",
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/ann_bayda.webp",
        name: "Ann Bayda",
        text: "Head of Business Development",
        link: "https://www.linkedin.com/in/ann-bayda/",
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/nika_boeva.webp",
        name: "Nika Boieva",
        text: "Head of People",
        link: "https://www.linkedin.com/in/nika-boieva-%F0%9F%87%BA%F0%9F%87%A6-9aa997242/",
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-management/katia_yatsenko.webp",
        name: "Katia Yatsenko",
        text: "Head of Marketing",
        link: "https://www.linkedin.com/in/katia-yatsenko-150406222/",
    }
]
