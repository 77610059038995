import React, { useEffect } from "react"
import "./index.scss"
import { Link } from "react-router-dom"
import { NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"

const Error = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Helmet defer={false}>
                <title>Error - Newsoft</title>
            </Helmet>
            <div className="error">
                <div className="error-content">
                    <p className="error-content-code">404</p>
                    <h3 className="t-h3">PAGE NOT FOUND</h3>
                    <p className="error-content-message t-b4">
                        It seems that the link you followed may be outdated or
                        the page may have been removed. We apologize for any
                        inconvenience this may have caused.
                    </p>
                    <button className="error-content-button">
                        <Link to={NAVIGATION_LINKS.HOME} className="t-s4">
                            Go back home
                        </Link>
                    </button>
                </div>
            </div>
        </>
    )
}

export default Error
