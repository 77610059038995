import React from "react"
import BaseIcon from "./BaseIcon"

const CrossPlatformDevelopmentIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 45.5L55 30.5L40 15.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20 15.5L5 30.5L20 45.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default CrossPlatformDevelopmentIcon
