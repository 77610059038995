import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"
import FirebaseLogo from "../../assets/images/technologies/firebase.svg"
import KotlinLogo from "../../assets/images/technologies/kotlin.svg"
import RealmLogo from "../../assets/images/technologies/realm.svg"
import AndroidLogo from "../../assets/images/technologies/android.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    HELLO_CHALLENGES, HELLO_CLIENT_FEEDBACK, HELLO_HEADER,
    HELLO_PROJECT_OVERVIEW, HELLO_RATINGS, HELLO_SOLUTIONS
} from "../../data/cases/hello"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Hello = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>Hello - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template hello">
                <CaseHeader header={HELLO_HEADER.header} desc={HELLO_HEADER.desc} duration={HELLO_HEADER.duration}
                    image={HELLO_HEADER.image} industry={HELLO_HEADER.industry} service={HELLO_HEADER.service} location={HELLO_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={HELLO_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={HELLO_SOLUTIONS} challenges={HELLO_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={FirebaseLogo} title="Firebase"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={KotlinLogo} title="Kotlin"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={RealmLogo} title="Realm"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AndroidLogo} title="Android"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={HELLO_CLIENT_FEEDBACK.text} company={HELLO_CLIENT_FEEDBACK.company} image={HELLO_CLIENT_FEEDBACK.image}
                    linkedin={HELLO_CLIENT_FEEDBACK.linkedin} name={HELLO_CLIENT_FEEDBACK.name} desc={HELLO_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={HELLO_RATINGS.clutch} mark={HELLO_RATINGS.mark}  points={HELLO_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.HELLO} />
            <BookACall/>
        </>
    )
}

export default Hello
