import React from "react"
// import PlayIcon from "../../components/icons/PlayIcon"
import "./index.scss"

export const VideoBlock = () => (
    <div className="video-wrapper">
        <div className="video-block">
            <iframe
                src="https://www.youtube.com/embed/w8rK2l1ZZoA"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                style={{ border: "none" }}
            ></iframe>
        </div>
    </div>
)

// export const VideoBlock = () => {
//     const [showControls, setShowControls] = useState(false)
//     const [player, setPlayer] = useState(false)

//     const src =
//         "https://newsoft-ns.com/media/Final_NewSoft.mp4"

//     useEffect(() => {
//         setPlayer(document.getElementById("video-element"))
//     }, [])

//     function playPauseVideo() {
//         if (player.paused || player.ended) {
//             player.play()
//         }
//     }

//     return (
//         <div className="video-wrapper">
//             <div className="video-block">
//                 <video
//                     id="video-element"
//                     controls={showControls}
//                     width="100%"
//                     preload="none"
//                     poster="https://newsoft-ns.com/media/video_poster.webp"
//                 >
//                     <source src={src} type="video/mp4" />
//                 </video>
//                 {!showControls && (
//                     <PlayIcon
//                         id="btn-play"
//                         onClick={() => {
//                             setShowControls(true)
//                             playPauseVideo()
//                         }}
//                         width={65}
//                         height={65}
//                     />
//                 )}
//             </div>
//         </div>
//     )
// }
