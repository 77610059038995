import React, { useEffect } from "react"
import GridLayout from "../../layouts/GridLayout"

import "./index.scss"
import { BookACall } from "../../features/book-a-call"


const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <GridLayout>
                <div className="privacy-policy">
                    <h1>Privacy Policy</h1>
                    <div>
                        <p><strong>Updated 21.03.2024</strong></p>
                        <br/>
                        <p>
                At Newsoft, We are committed to protecting the privacy and security of your personal information. This Privacy Policy explains how we collect, use, disclose, and protect your personal data when you visit our website or use our services. By accessing or using our website, you agree to the terms and conditions of this Privacy Policy.
                        </p>
                        <br/>
                        <p><strong>Information we collect</strong></p>
                        <ul>
                            <li>
                                <strong>Personal Information</strong>
                                <p>We may collect personal information, such as your name, email address, contact number, and job title, when you voluntarily provide it to us through our website or during the course of our business interactions.</p>
                            </li>
                            <li>
                                <strong>Usage Data</strong>
                                <p>We may collect non-personal information about your visit to our website, including your IP address, browser type, operating system, referring/exit pages, and the date/time of your visit. This information is used to analyze trends, administer the site, track user movements, and gather demographic information.</p>
                            </li>
                        </ul>
                        <br/>
                        <p><strong>Use of Information</strong></p>
                        <ul>
                            <li>
                                <strong>Personal Information</strong>
                                <p>We may use your personal information to:</p>
                                <ul>
                                    <li>Provide and improve our services</li>
                                    <li>Respond to your inquiries and fulfill your requests</li>
                                    <li>Communicate with you about our products, services, and updates</li>
                                    <li>Process transactions and send you related information</li>
                                    <li>Personalize your experience on our website</li>
                                    <li>Conduct research and analysis to enhance our offerings</li>
                                    <li>Comply with applicable laws and regulations</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Usage Data</strong>
                                <p>We may use non-personal information to:</p>
                                <ul>
                                    <li>Analyze trends</li>
                                    <li>Administer our website</li>
                                    <li>Track user movements</li>
                                    <li>Gather demographic information</li>
                                </ul>
                            </li>
                            <p>This data helps us improve our website and tailor it to our visitors&#39; needs.</p>
                        </ul>
                        <br/>
                        <p><strong>Data Sharing and Disclosure</strong></p>
                        <ul>
                            <li>
                                <strong>Service Providers</strong>
                                <p>We may share your personal information with trusted third-party service providers who assist us in operating our business and providing our services. These providers are obligated to use your information only for the purposes specified by us and in accordance with this Privacy Policy.</p>
                            </li>
                            <li>
                                <strong>Legal Requirements</strong>
                                <p>We may disclose your personal information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>
                            </li>
                        </ul>
                        <br/>
                        <p><strong>Data Security</strong></p>
                        <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
                        <br/>
                        <p><strong>Your Choices</strong></p>
                        <p>You have the right to access, correct, update, or delete your personal information. You may also opt-out of receiving promotional communications from us by following the instructions in those communications. Please note that even if you opt-out, we may still send you non-promotional messages related to your transactions or our ongoing business relationship.</p>
                        <br/>
                        <p><strong>Third-Party Links</strong></p>
                        <p>Our website may contain links to third-party websites or services. We are not responsible for the privacy practices or the content of such websites. We encourage you to review the privacy policies of those third parties before providing any personal information.</p>
                        <br/>
                        <p><strong>Changes to this Privacy Policy</strong></p>
                        <p>We reserve the right to modify or update this Privacy Policy at any time. We will post the revised version on our website with the updated effective date. We encourage you to review this Privacy Policy periodically for any changes.</p>
                        <br/>
                        <p><strong>Cookie policy</strong></p>
                        <p>We use cookies and similar tracking technologies to monitor activity on our website and store certain information.</p>
                        <p>You can configure your browser to block all cookies or to alert you when cookies are being sent. However, if you do not accept cookies, you may not be able to use some sections of our website.</p>
                        <p>Cookies can be &quot;persistent&quot; or &quot;session&quot; cookies. Persistent cookies remain on your personal computer or mobile device when you go offline, while session cookies are deleted as soon as you close your browser. We use both session and persistent cookies for the purposes described below:</p>
                        <br/>
                        <p><strong>Necessary/Essential Cookies</strong></p>
                        <p>Type: Session Cookies<br />
               Administrator: Newsoft<br />
               Purpose: These cookies are essential to provide you with services available through the website and to enable you to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these cookies, the services that you have requested cannot be provided. We use these cookies only to provide you with those services.</p>
                        <br/>
                        <p><strong>Cookie Usage Policy/ Cookie Acceptance Notification</strong></p>
                        <p>Type: Persistent Cookies<br />
               Administrator: Newsoft<br />
               Purpose: These cookies determine whether users have accepted the use of cookies on the website.</p>
                        <br/>
                        <p><strong>Functional Cookies</strong></p>
                        <p>Type: Persistent Cookies<br />
               Administrator: Newsoft<br />
               Purpose: These cookies allow us to remember the choices you make while using the website, such as remembering your login details or language preferences. The purpose of these cookies is to provide you with a more personal experience and to avoid you having to re-enter your preferences every time you use our site.</p>
                        <br/>
                        <p><strong>Contact Us</strong></p>
                        <p>If you have any questions or concerns about our Privacy and Policy or the way we handle your personal information, please contact us at <a href="mailto:support@newsoft-ns.com">support@newsoft-ns.com</a>.</p>
                    </div>
                </div>
            </GridLayout>
            <BookACall />
        </>
    )
}

export default PrivacyPolicy
