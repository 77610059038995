import React from "react"
import { useSwiper } from "swiper/react"
import SwiperArrowLeft from "../icons/SwiperArrowLeft"

export const SwiperButtonPrevious = ({
    setArticleNumber,
    articleNumber,

    setGalleryPage,
    galleryPage,

    setTestimonialNumber,
    testimonialNumber,

    className,
    swipe=true,
    disabled=false
}) => {
    const swiper = useSwiper()

    return (
        <button
            className={className}
            disabled={disabled}
            onClick={() => {
                swipe && swiper.slidePrev()
                setArticleNumber && setArticleNumber(articleNumber - 1)
                setGalleryPage && setGalleryPage(galleryPage - 1)
                setTestimonialNumber && setTestimonialNumber(testimonialNumber - 1)
            }}
        >
            <SwiperArrowLeft width={24} height={44} />
        </button>
    )
}