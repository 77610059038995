import React from "react"
import "./index.scss"

const CaseChallengesSolutions = ({challenges, solutions}) => {
    return (
        <div className="case-template-challenges-solutions">
            <div className="case-template-challenges-solutions-challenges">
                <h3 className="t-h-m-22">Challenges</h3>
                <div className="case-template-challenges-solutions-challenges-list">
                    {challenges.map((el, i) => (
                        <div className="case-template-challenges-solutions-challenges-list-item" key={i}>
                            <p>― </p>
                            <p className="t-s-18">{el}</p>
                        </div>
                    ))}
                </div>
            </div>

            <hr/>

            <div className="case-template-challenges-solutions-solutions">
                <h3 className="t-h-m-22">Solutions</h3>
                <div className="case-template-challenges-solutions-solutions-list">
                    {solutions.map((el, i) => (
                        <div className="case-template-challenges-solutions-solutions-list-item" key={i}>
                            <p>― </p>
                            <p className="t-s-18">{el}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CaseChallengesSolutions
