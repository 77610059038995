import React from "react"
import LazyImage from "../../../components/LazyImage"
import { handleCardClick } from "../../../utils"

export const Card = ({ image, imageAlt, header, text, link }) => {
    return (
        <div className="card" onClick={(e) => handleCardClick(e, link)} >
            <LazyImage alt={imageAlt || "service card image"} src={image} className="card-image" />
            <div className="card-text">
                <h4 className="t-h-18">{header}</h4>
                <p className="t-b4">{text}</p>
            </div>
        </div>
    )
}
