import React from "react"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import { ServiceHeader } from "../../features/service-header"
import {
    CUSTOM_SOFTWARE_DEVELOPMENT_CASES,
    CUSTOM_SOFTWARE_DEVELOPMENT_CLIENTS,
    CUSTOM_SOFTWARE_DEVELOPMENT_HEADER,
    CUSTOM_SOFTWARE_DEVELOPMENT_PROCESS,
    CUSTOM_SOFTWARE_DEVELOPMENT_SERVICES,
    CUSTOM_SOFTWARE_DEVELOPMENT_WHY
} from "../../data/services/custom-software-development"
import { ServiceServices } from "../../features/service-services"
import BigNumList from "../../features/big-num-list"
import RoundedList from "../../features/rounded-list"
import { OurCases } from "../../features/our-cases"
import { OUR_CASES } from "../../data/home/our-cases"
import { OurTestimonials } from "../../features/our-testimonials"
import { OUR_TESTIMONIALS } from "../../data/home/our-testimonials"
import ServiceTemplate from "../../templates/service"


const CustomSoftwareDevelopment = () => {
    return (
        <>
            <Helmet defer={false}>
                <title>Custom Software Development - Newsoft</title>
            </Helmet>
            <ServiceTemplate>
                <ServiceHeader 
                    header={CUSTOM_SOFTWARE_DEVELOPMENT_HEADER.header}
                    desc={CUSTOM_SOFTWARE_DEVELOPMENT_HEADER.desc}
                    image={CUSTOM_SOFTWARE_DEVELOPMENT_HEADER.image}/>

                <ServiceServices
                    header={CUSTOM_SOFTWARE_DEVELOPMENT_SERVICES.header}
                    services={CUSTOM_SOFTWARE_DEVELOPMENT_SERVICES.services}/>

                <BigNumList
                    header="Development process"
                    list={CUSTOM_SOFTWARE_DEVELOPMENT_PROCESS}
                    className="service-template-big-num-list"
                    mode={"light"}/>

                <RoundedList className={"service-template-why"} header="Why Newsoft?" list={CUSTOM_SOFTWARE_DEVELOPMENT_WHY} />
                
                <OurCases size={3} cases={OUR_CASES.filter(o => CUSTOM_SOFTWARE_DEVELOPMENT_CASES.includes(o.header))}/>
                
                <OurTestimonials testimonials={OUR_TESTIMONIALS.filter(o => CUSTOM_SOFTWARE_DEVELOPMENT_CLIENTS.includes(o.company))}/>
            </ServiceTemplate>
            <BookACall/>
        </>
    )
}

export default CustomSoftwareDevelopment
