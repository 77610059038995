import React from "react"
import LazyImage from "../../components/LazyImage"
import "./index.scss"
import { handleCardClick } from "../../utils"

export const OneCase = ({index, header, desc, link, image, industry, duration, location, service, color, alt}) => {

    return (
        <div onClick={(e) => handleCardClick(e, link)} className={`one-case ${index % 2 !== 0 ? "reverse" : ""}`} style={{background: `${color}`}}>
            <div className="one-case-image">
                <LazyImage src={image} alt={alt} />
            </div>
            <div className="one-case-content">
                <h4 className="t-h-m-14">{header}</h4>
                <h3 className="t-h-m-20">{desc}</h3>
                <div className="one-case-content-extra">
                    <div className="one-case-content-extra-industry">
                        <div className="one-case-content-extra-text">
                            <h5 className="t-l-10">Industry</h5>
                            <p className="t-s-12">{industry.label}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="one-case-content-extra-duration">
                        <div className="one-case-content-extra-text">
                            <h5 className="t-l-10">Duration</h5>
                            <p className="t-s-12">{duration}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="one-case-content-extra-location">
                        <div className="one-case-content-extra-text">
                            <h5 className="t-l-10">Location</h5>
                            <p className="t-s-12">{location}</p>
                        </div>
                        <hr/>
                    </div>
                    <div className="one-case-content-extra-service">
                        <h5 className="t-l-10">Service</h5>
                        <div className="one-case-content-extra-service-list">
                            {service.map((el, id) => (
                                <p className="t-s-12" key={id}>{el.label}</p>)
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
