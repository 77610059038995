import React from "react"
import BaseIcon from "./BaseIcon"

const CloseIcon = ({ color, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M18 6L6 18" stroke={color ? color : "#B7B7B7"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6 6L18 18" stroke={color ? color : "#B7B7B7"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default CloseIcon
