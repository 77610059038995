export const IT_STAFF_AUGMENTATION_HEADER = {
    header: "IT Staff Augmentation",
    desc: "Taking a complex approach, we offer expanding the client's team with our skilled engineers. We strive to harmoniously blend talents and expertise for successful outcomes.",
    image: "https://newsoft-ns.com/media/services/it_staff_augmentation_main.webp"
}

export const IT_STAFF_AUGMENTATION_SERVICES = {
    header: "IT Staff Augmentation services",
    services: [
        {   
            header: "Blended Teams",
            desc: "We analyze your needs and integrate experts into your team, ensuring a perfect blend of talent and expertise for successful outcomes."
        },
        {   
            header: "Dedicated Teams",
            desc: "Our experts work under your supervision, developing and taking full responsibility for the assigned projects or modules."
        },
        {   
            header: "Managed Teams",
            desc: "We take full responsibility for the technical side. Our Technical Director leads developers to align with your company goals, ensuring efficiency and high-quality results through a deep understanding of your tech needs."
        }
    ]
}

export const IT_STAFF_AUGMENTATION_PROCESS = [
    {
        number: "01",
        header: "Introductory meeting. Outlining requirements",
        text: "We analyze your project idea or existing development needs together and offer you the best-matching team setup.",
    },
    {
        number: "02",
        header: "Building a team for you",
        text: "We carry out interview sessions with potential candidates, ensuring the selection of the best individuals with strong technical and soft skills that align with your specific needs. The final decision remains entirely in your hands, allowing for additional interviews on your side if necessary.",
    },
    {
        number: "03",
        header: "Kicking off",
        text: "We onboard your chosen development team and get the project rolling.",
    },
    {
        number: "04",
        header: "Sustained Team Support",
        text: "We provide your dedicated team with a wide range of services including comfortable fully-equipped office space, HR and professional growth support, performance reviews, regular English classes, and accounting services.",
    }
]

export const IT_STAFF_AUGMENTATION_WHY = [
    "Team of experts",
    "Customized solutions",
    "Client-centric approach",
    "Flexible engagement models",
    "Transparency & Trust"
]

export const IT_STAFF_AUGMENTATION_CASES = [
    "Packsmith", "EvolveYou"
]

export const IT_STAFF_AUGMENTATION_CLIENTS = [
    "Packsmith", "EvolveYou App Ltd"
]