export const TABLET_WIDTH = 720
export const DESKTOP_WIDTH = 1024
export const MONITOR_WIDTH = 1440

export const MAIN_SECTION = {
    BOOK_A_CALL: "book_a_call",
    ABOUT_US_HISTORY: "about_us_history"
}

export const NAVIGATION_LINKS = {
    HOME: "/",
    CASES: "/cases",
    TRYNOW: "/cases/trynow",
    TRYNOW_OLD: "/cases/try-now",
    EVOLVEYOU: "/cases/evolveyou",
    BAZO: "/cases/bazo",
    HELLO: "/cases/hello",
    CRAFT: "/cases/craft",
    PACKSMITH: "/cases/packsmith",
    ORBIT: "/cases/orbit",
    PEDNET: "/cases/pednet",
    ERROR: "/error",

    ABOUT_US: "/about-us",
    SERVISES: "/services",
    IT_STAFF_AUGMENTATION: "/services/it-staff-augmentation",
    CUSTOM_SOFTWARE_DEVELOPMENT: "/services/custom-software-development",

    PRIVACY_POLICY: "/privacy-policy",
    TERMS_AND_CONDITIONS: "/terms-and-conditions",
    CONTACT_US: "/contact-us",

    INDUSTRIES: "/industries",
    E_COMMERCE: "/industries/e-commerce",
    SPORT_AND_WELLNESS: "/industries/sport-and-wellness",

    DONATE: "/donate",
}

export const ANALYTICS_TRACKER_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID

export const SERVICES_OPTIONS = {
    ALL: {label: "All", value: "all"},
    CUSTOM_SOFTWARE_DEVELOPMENT: {label: "Custom Software Development", value: "custom_software_development"},
    IT_STAFF_AUGMENTATION: {label: "IT Staff Augmentation", value: "it_staff_augmentation"},
}

export const INDUSTRIES_OPTIONS = {
    ALL: {label: "All", value: "all"},
    E_COMMERCE: {label: "E-Commerce", value: "e_commerce"},
    SPORT_WELLNESS: {label: "Sport & Wellness", value: "sport_wellness"},
    SOCIAL_NETWORK: {label: "Social Network", value: "social_network"},
    FINANCIAL_SERVICES: {label: "Financial Services", value: "financial_services"},
    STAFFING_RECRUITING: {label: "Staffing/Recruiting", value: "staffing_recruiting"},
    ENVIRONMENTAL_SOLUTIONS: {label: "Environmental Solutions", value: "environmental_solutions"}
}
