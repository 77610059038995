import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"

import AWSLogo from "../../assets/images/technologies/aws.svg"
import PythonLogo from "../../assets/images/technologies/python.svg"
import CeleryLogo from "../../assets/images/technologies/celery.svg"
import ReactLogo from "../../assets/images/technologies/react.svg"
import DjangoLogo from "../../assets/images/technologies/django.svg"
import SendgridLogo from "../../assets/images/technologies/sendgrid.svg"
import PostgresLogo from "../../assets/images/technologies/postgresql.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    ORBIT_CHALLENGES, ORBIT_CLIENT_FEEDBACK, ORBIT_HEADER,
    ORBIT_PROJECT_OVERVIEW, ORBIT_RATINGS, ORBIT_SOLUTIONS
} from "../../data/cases/orbit"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Orbit = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>Orbit - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template orbit">
                <CaseHeader header={ORBIT_HEADER.header} desc={ORBIT_HEADER.desc} duration={ORBIT_HEADER.duration}
                    image={ORBIT_HEADER.image} industry={ORBIT_HEADER.industry} service={ORBIT_HEADER.service} location={ORBIT_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={ORBIT_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={ORBIT_SOLUTIONS} challenges={ORBIT_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={PythonLogo} title="Python"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PostgresLogo} title="PostgreSQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SendgridLogo} title="SendGrid"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSLogo} title="AWS"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReactLogo} title="React"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DjangoLogo} title="Django"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={CeleryLogo} title="Celery"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={ORBIT_CLIENT_FEEDBACK.text} company={ORBIT_CLIENT_FEEDBACK.company} image={ORBIT_CLIENT_FEEDBACK.image}
                    linkedin={ORBIT_CLIENT_FEEDBACK.linkedin} name={ORBIT_CLIENT_FEEDBACK.name} desc={ORBIT_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={ORBIT_RATINGS.clutch} mark={ORBIT_RATINGS.mark} points={ORBIT_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.ORBIT} />
            <BookACall/>
        </>
    )
}

export default Orbit
