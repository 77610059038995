import { NAVIGATION_LINKS } from "../../constants/common"

export const CRAFT_HEADER = {
    image: "https://newsoft-ns.com/media/cases/craft.webp",
    imageAlt: "Craft & Newsoft",
    header: "Craft",
    desc: "Custom Software Development for an American growth fund",
    link: NAVIGATION_LINKS.CRAFT,
    industry: {label: "Financial Services", value: "financial_services"},
    duration: "1,5 years",
    location: "USA",
    service: [
        {label: "Custom Software Development", value: "custom_software_development"},
    ],
    color: "#023047"
}

export const CRAFT_PROJECT_OVERVIEW = [
    "Craft Ventures is a growth fund dedicated to the craft of building great companies. The project aimed to simplify the analytical process for the business. Instead of conducting all the analytical process, you can easily upload an ExcelSheet and the website does all the work. The Newsoft team was tasked with developing a set of customized features, such as a variety of dashboards, graphics, and visualization options to cater to the user's needs. ",
    "Team: FullStack Engineer, QA Engineer, and two Python Engineers."
]

export const CRAFT_CHALLENGES = [
    "Implement extensive customization options",
    "Develop user-centric functionality to enhance customer satisfaction and engagement",
    "Efficiently manage and process large datasets for optimal performance",
    "Shift focus from individual user to organizational requirements to better serve enterprise clients",
    "Conduct thorough market research and analysis",
    "Refine core components and build robust business logic to ensure seamless operation",
    "Establish comprehensive testing procedures to maintain high quality and reliability"
]

export const CRAFT_SOLUTIONS = [
    "Integrated customization features to deliver tailored user experiences",
    "Employed thorough debugging and performance testing to ensure efficient and reliable data processing",
    "Defined and adhered to structured development phases, encompassing requirements gathering, implementation, and continuous support, to maintain project alignment and quality",
    "Implemented a flexible plan for model and architecture updates, adapting to evolving requirements during development"
]

export const CRAFT_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/bryan_pratte.webp",
    name: "Bryan Pratte",
    desc: "Co-Founder & CEO",
    company: "QuarkWorks",
    linkedin: "https://www.linkedin.com/in/bryanpratte/",
    text: [
        "“Since Newsoft joined the project, the team has scaled and their efficiency has skyrocketed. Their knowledge combined with their friendly, efficient team makes them a reliable partner that has exceeded the client's expectations.",
        "If I could hire more people from them for specific roles I have open, I would. We haven't had a bad candidate from them yet, and that's almost unheard of in the industry. Newsoft's team are engineers first. They really understand how to build and grow engineering teams. That's really the reason why we decided to work with them.”"
    ],
}

export const CRAFT_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-106659",
    points: [
        {
            mark: "4.5",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}