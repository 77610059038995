import React from "react"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH } from "../../constants/common"

import "./index.scss"
import LazyImage from "../../components/LazyImage"
import AppLink from "../../components/AppLink"
import ClutchLogo from "../../assets/images/clutchWhite.svg"


export const OneTestimonial = ({el}) => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <div className="one-testimonial">
            {!isDesktop && 
            <div className="one-testimonial-info-rating">
                <AppLink href={el.clutch}>
                    <img src={ClutchLogo} width={88} height={22} alt="Clutch logo"/>
                </AppLink>

                <p>★ {el.rating}</p>
            </div>}
            <div className="one-testimonial-description">
                {el.text.map((t, index) => (
                    <p key={index} className="t-s-14">{t}</p>
                ))}
            </div>
            <div className="one-testimonial-info">
                <div className="one-testimonial-info-client">
                    <div className="one-testimonial-info-client-image">
                        <LazyImage
                            alt="card"
                            src={el.image}
                        />
                    </div>
                    <div className="one-testimonial-info-client-info">
                        <h4 className="t-n-m-20">{el.name}</h4>
                        <hr/>
                        <div className="one-testimonial-info-client-info-desc">
                            <p className="t-s-12">{el.desc} at  
                                <strong className="one-testimonial-info-client-info-desc-company">  {el.company}</strong>
                            </p>
                        </div>
                        <AppLink className="t-s-12 clickable-link-black-background" href={el.linkedin}>
                                LinkedIn
                        </AppLink>
                    </div>
                </div>
                {isDesktop && 
                <div className="one-testimonial-info-rating">
                    <AppLink href={el.clutch}>
                        <img src={ClutchLogo} width={88} height={22} alt="Clutch logo"/>
                    </AppLink>

                    <p>★ {el.rating}</p>
                </div>
                }
            </div>
        </div>
    )
}
