import { NAVIGATION_LINKS } from "../../constants/common"

export const BAZO_HEADER = {
    image: "https://newsoft-ns.com/media/cases/bazo.webp",
    imageAlt: "Bazo & Newsoft",
    header: "Bazo",
    desc: "Custom Software Development for a SAAS Solution",
    link: NAVIGATION_LINKS.BAZO,
    industry: {label: "E-Commerce", value: "e_commerce"},
    duration: "1 year (ongoing)",
    location: "USA",
    service: [
        {label: "Custom Software Development", value: "custom_software_development"},
        {label: "E-Commerce Development", value: "e_commerce_development"},
        {label: "Web Development", value: "web_development"},
        {label: "Shopify App Development and Integration", value: "shopify_app_development_and_integration"}
    ],
    color: "#008F70"
}

export const BAZO_PROJECT_OVERVIEW = [
    "Bazo is a SaaS solution that enables direct-to-consumer brands in selling stagnant inventory profitably. The Newsoft team was tasked with building an application that provides retailers with an opportunity to sell their goods with the Name Your Own Price option. It allows clients to name their own price for a certain product and buy goods in an auction-style marketplace.",
    "Team: Lead Software Engineer (Shopify Expert), FullStack Developer, 2 QA Engineers, and UI/UX Designer."
]

export const BAZO_CHALLENGES = [
    "Build Minimum Viable Product (MVP) and the second iteration",
    "Develop Name Your Own Price API",
    "Design an intuitive and engaging application",
    "Develop and integrate seamlessly a new feature for Shopify stores",
    "Implement usage-based billing system",
    "Implement reliable store billing mechanisms",
    "Ensure the reliability and scalability of the application", 
    "Develop inventory liquidation solutions for stores",
    "Automate customer order notifications",
    "Conduct rigorous testing and real-time analytics"
]

export const BAZO_SOLUTIONS = [
    "Built a Shopify application using the newly released Shopify APIs",
    "Implemented Shopify Deferred Purchase, Discount Functions, and other APIs to ensure accurate and timely billing",
    "Created an application that provides valuable tools and features to improve store management and performance",
    "Implemented flexible billing mechanisms within the Shopify ecosystem, offering both subscription-based and usage-based options",
    "Created a robust database structure, APIs, and scheduled tasks to efficiently process a high volume of orders",
    "Utilized SendGrid transactional templates and logic to automate email notifications, enhancing communication and customer engagement",
    "Conducted thorough testing and quality assurance"
]

export const BAZO_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/brandon_younessi.webp",
    name: "Brandon Younessi",
    desc: "CEO",
    company: "Bazo",
    linkedin: "https://www.linkedin.com/in/brandonyounessi/",
    text: [
        "“Newsoft has proven to be a reliable and talented partner, providing top-notch quality work as if they were part of the client's team.",
        "I've worked with a lot of contractors in my 12 years building software and they are by far the most reliable. I will be using them for any startup I launch going forward. Domain expertise, client service, quality talent and operating model is top-notch. They are all in office and act as full time employees but these guys are straight up our employees and they're amazing.”"
    ],
}

export const BAZO_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft?page=1#review-233931",
    points: [
        {
            mark: "5.0",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}