import React from "react"
import "./index.scss"
import AppLink from "../../components/AppLink"

export const OneArticle = ({el}) => {

    return (
        <div className="one-article">
            <div className="one-article-content">
                <h3 className="t-h-m-20">{el.header}</h3>
                <p className="t-s-14">{el.text}</p>
            </div>
            <div className="one-article-info">
                <p className="t-s-12">{el.time}</p>
                <AppLink className="cta-link clickable-link-black-background" href={el.link}>
                        Learn more
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <path d="M18 13.5V19.5C18 20.0304 17.7893 20.5391 17.4142 20.9142C17.0391 21.2893 16.5304 21.5 16 21.5H5C4.46957 21.5 3.96086 21.2893 3.58579 20.9142C3.21071 20.5391 3 20.0304 3 19.5V8.5C3 7.96957 3.21071 7.46086 3.58579 7.08579C3.96086 6.71071 4.46957 6.5 5 6.5H11" stroke="white" strokeOpacity="0.7" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M15 3.5H21V9.5" stroke="white" strokeOpacity="0.7" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M10 14.5L21 3.5" stroke="white" strokeOpacity="0.7" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </AppLink>
            </div>
        </div>
    )
}
