import React from "react"
import { useSpring, animated } from "react-spring"
import "./index.scss"

const Number = ({ n }) => {
    const { number } = useSpring({
        from: { number: 0 },
        number: n,
        delay: 200,
        config: { mass: 1, tension: 20, friction: 10 },
    })
    return <animated.span>{number.to((n) => n.toFixed(0))}</animated.span>
}

export const MetricsBlock = ({ list }) => {
    return (
        <div
            className="metrics-block"
        >
            <h2 className="t-s3">Newsoft in numbers</h2>
            <div className="metrics-block-items">
                {list.map((el, i) => (
                    <div className="metrics-block-items-item" key={i}>
                        <div className={"metrics-block-number"}>
                            <Number n={el.number} />
                            {el.plus && (
                                <div className="metrics-block-number-plus">
                                    {el.plus}
                                </div>
                            )}
                        </div>
                        <p className="t-s-14">{el.desc}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}
