import React from "react"
import BaseIcon from "./BaseIcon"

const ECommerceSolutionIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.5 55.5C23.8807 55.5 25 54.3807 25 53C25 51.6193 23.8807 50.5 22.5 50.5C21.1193 50.5 20 51.6193 20 53C20 54.3807 21.1193 55.5 22.5 55.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50 55.5C51.3807 55.5 52.5 54.3807 52.5 53C52.5 51.6193 51.3807 50.5 50 50.5C48.6193 50.5 47.5 51.6193 47.5 53C47.5 54.3807 48.6193 55.5 50 55.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.5 3H12.5L19.2 36.475C19.4286 37.626 20.0548 38.6599 20.9689 39.3957C21.883 40.1316 23.0267 40.5225 24.2 40.5H48.5C49.6733 40.5225 50.817 40.1316 51.7311 39.3957C52.6452 38.6599 53.2714 37.626 53.5 36.475L57.5 15.5H15" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default ECommerceSolutionIcon
