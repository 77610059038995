import React from "react"
import BaseIcon from "./BaseIcon"

const DataAnalyticsIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 20.5C42.4264 20.5 52.5 17.1421 52.5 13C52.5 8.85786 42.4264 5.5 30 5.5C17.5736 5.5 7.5 8.85786 7.5 13C7.5 17.1421 17.5736 20.5 30 20.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M52.5 30.5C52.5 34.65 42.5 38 30 38C17.5 38 7.5 34.65 7.5 30.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M7.5 13V48C7.5 52.15 17.5 55.5 30 55.5C42.5 55.5 52.5 52.15 52.5 48V13" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default DataAnalyticsIcon
