import React from "react"
import { ArrowGetInTouch } from "../../components/icons/Arrow"

import "./index.scss"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Link } from "react-router-dom"


export const IndustryOverviewAwards = ({overview, clutchAwards, manifestAwards}) => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <div className="industry-template-info">
            <div className="industry-template-info-overview">
                <h2 className="t-s3">Overview</h2>

                <div className="industry-template-info-overview-text">
                    {overview.map((el, id) => (
                        <p className="t-s-14" key={id}>{el}</p>
                    ))}
                </div>

                <Link className="industry-template-info-overview-cta clickable-link-black-background"to={NAVIGATION_LINKS.CONTACT_US}>
                    <a className="t-s-14">get in touch</a>
                    <ArrowGetInTouch width={18} color={"#B7B7B7"} />
                </Link>
            </div>
            <hr/>

            <div className="industry-template-info-awards">
                <h2 className="t-s3">Awards</h2>

                <div className="industry-template-info-awards-content">
                    <div className="industry-template-info-awards-content-clutch">
                        {clutchAwards.map((el, id) => (
                            <img key={id} src={el} width={isDesktop ? "129px" : "73px"} height={isDesktop ? "140px" : "80px"} />
                        ))}
                    </div>

                    <div className="industry-template-info-awards-content-manifest">
                        {manifestAwards.map((el, id) => (
                            <img key={id} src={el} width={isDesktop ? "98px": "55px"} height={isDesktop ? "140px" : "80px"}/>
                        ))}
                    </div>
                </div>

            </div>
        </div>
    )
}
