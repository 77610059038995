import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"
import PythonLogo from "../../assets/images/technologies/python.svg"
import AWSLogo from "../../assets/images/technologies/aws.svg"
import DjangoLogo from "../../assets/images/technologies/django.svg"
import AtomLogo from "../../assets/images/technologies/atom.svg"
import ServerlessLogo from "../../assets/images/technologies/serverless.svg"
import ShopifyLogo from "../../assets/images/technologies/shopify.svg"
import StripeLogo from "../../assets/images/technologies/stripe.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    TRYNOW_CHALLENGES, TRYNOW_CLIENT_FEEDBACK, TRYNOW_HEADER,
    TRYNOW_PROJECT_OVERVIEW, TRYNOW_RATINGS, TRYNOW_SOLUTIONS
} from "../../data/cases/trynow"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const TryNow = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>TryNow - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template try-now">
                <CaseHeader header={TRYNOW_HEADER.header} desc={TRYNOW_HEADER.desc} duration={TRYNOW_HEADER.duration}
                    image={TRYNOW_HEADER.image} industry={TRYNOW_HEADER.industry} service={TRYNOW_HEADER.service} location={TRYNOW_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={TRYNOW_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={TRYNOW_SOLUTIONS} challenges={TRYNOW_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={StripeLogo} title="Stripe"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PythonLogo} title="Python"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSLogo} title="AWS"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ServerlessLogo} title="Serverless"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DjangoLogo} title="Django"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ShopifyLogo} title="Shopify"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AtomLogo} title="Atom"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={TRYNOW_CLIENT_FEEDBACK.text} company={TRYNOW_CLIENT_FEEDBACK.company} image={TRYNOW_CLIENT_FEEDBACK.image}
                    linkedin={TRYNOW_CLIENT_FEEDBACK.linkedin} name={TRYNOW_CLIENT_FEEDBACK.name} desc={TRYNOW_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={TRYNOW_RATINGS.clutch} mark={TRYNOW_RATINGS.mark} points={TRYNOW_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.TRYNOW} />
            <BookACall/>
        </>
    )
}

export default TryNow
