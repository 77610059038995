import React from "react"

import "./index.scss"
import { useMediaQuery } from "react-responsive"
import { TABLET_WIDTH } from "../../constants/common"
import LazyImage from "../../components/LazyImage"
import { Link } from "react-router-dom"


export const IndustryInto = ({industry, key }) => {
    const isTablet = useMediaQuery({minWidth: TABLET_WIDTH})

    return (
        <div key={key} className="industry-intro">
            <LazyImage alt={"industry card image"} src={isTablet ? industry.image : industry.mobileImage} className="industry-intro-image" />
            <div className="industry-intro-content">
                <div className="industry-intro-content-info">
                    <p className="t-s-12">Check our expertise in</p>
                    <h2 className="t-h-24">{industry.header}</h2>
                </div>
                <Link className="industry-intro-content-link" to={industry.link}>
                    <p className="t-s-12">read more</p>
                </Link>
            </div>
        </div>
    )
}
