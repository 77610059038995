import "./styles/common.scss"
import "./styles/index.scss"

import React from "react"
import {BrowserRouter as Router} from "react-router-dom"
import AppRoutes from "./routes/Routes"
import ReactGA from "react-ga"
import { ANALYTICS_TRACKER_ID } from "./constants/common"

ReactGA.initialize(ANALYTICS_TRACKER_ID)

const App = () => {  
    return (
        <Router>
            <AppRoutes/>
        </Router>
    )
}

export default App
