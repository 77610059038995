import React from "react"
import ClutchLogo from "../../assets/images/clutchBlack.svg"

import "./index.scss"
import AppLink from "../../components/AppLink"

const CaseRatings = ({clutch, mark, points}) => {
    return (
        <div className="case-template-ratings">
            <h3 className="t-h-m-22">Ratings from client</h3>
            <div className="case-template-ratings-content">
                <div className="case-template-ratings-content-mark">
                    <div className="case-template-ratings-content-mark-stars">
                        <p className="case-template-ratings-content-mark-stars-number">{mark}</p>
                        <div className="stars" id="stars">
                            <span className="star" data-value="1">★</span>
                            <span className="star" data-value="2">★</span>
                            <span className="star" data-value="3">★</span>
                            <span className="star" data-value="4">★</span>
                            <span className="star" data-value="5">★</span>
                        </div>
                    </div>
                    <AppLink href={clutch}>
                        <img src={ClutchLogo} width={88} height={22} alt="Clutch logo"/>
                    </AppLink>
                </div>
                <div className="case-template-ratings-content-info">
                    {points.map((el, i)=> (
                        <div key={i} className="case-template-ratings-content-info-points">
                            <hr/>
                            <div className="case-template-ratings-content-info-points-point">
                                <div className="case-template-ratings-content-info-points-point-text">
                                    <p className="case-template-ratings-content-info-points-point-text-title t-s-18">{el.title}</p>
                                    <p className="case-template-ratings-content-info-points-point-text-subtitle t-s-14">{el.subtitle}</p>
                                </div>
                                <div className="case-template-ratings-content-info-points-point-mark">{el.mark}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default CaseRatings
