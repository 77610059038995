import { NAVIGATION_LINKS } from "../../constants/common"

export const HELLO_HEADER = {
    image: "https://newsoft-ns.com/media/cases/hello.webp",
    imageAlt: "Hello & Newsoft",
    header: "Hello",
    desc: "Custom Software Development for an American Social Network",
    link: NAVIGATION_LINKS.HELLO,
    industry: {label: "Social Network", value: "social_network"},
    duration: "1,5 years",
    location: "USA",
    service: [
        {label: "Custom Software Development", value: "custom_software_development"},
        {label: "Mobile App Development", value: "mobile_app_development"}        
    ],
    color: "#513BBD"
}

export const HELLO_PROJECT_OVERVIEW = [
    "Hello is an application that aims to bring closer like-minded people. Gathered by the idea of connecting people on the basis of mutual interests, the Hello team set its target to change the way people interact socially. The Newsoft team was tasked with ensuring the app compatibility with various devices and performing the optimization and performance enhancements.",
    "Team: Team Lead and Android Developer."
]

export const HELLO_CHALLENGES = [
    "Improve the user interface and interactions to compete effectively with leading social networks",
    "Execute a redesign to alter the product's logic while maintaining usability",
    "Resolve compatibility challenges to ensure seamless functionality across diverse devices",
    "Effectively refactor legacy code to improve maintainability and avoid conflicts",
    "Resolve numerous bugs to enhance product stability and user satisfaction",
    "Enhance performance to meet user expectations and market demands",
    "Rebuild the database architecture without compromising data integrity or causing disruptions"
]

export const HELLO_SOLUTIONS = [
    "Developed a new version of the application to rival leading social networks like Facebook",
    "Enhanced user experience, driving engagement and retention",
    "Improved product stability and satisfaction, enhancing brand reputation",
    "Reduced maintenance costs while maximizing ROI",
    "Expanded audience reach and accessibility",
    "Increased visibility and user acquisition, driving revenue growth",
    "Ensured seamless operations and data integrity, preserving user trust and compliance"
]


export const HELLO_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/bryan_pratte.webp",
    name: "Bryan Pratte",
    desc: "Co-Founder & CEO",
    company: "Quarkworks",
    linkedin: "https://www.linkedin.com/in/bryanpratte/",
    text: [
        "“Since Newsoft joined the project, the team has scaled and their efficiency has skyrocketed. Their knowledge combined with their friendly, efficient team makes them a reliable partner that has exceeded the client's expectations.",
        "If I could hire more people from them for specific roles I have open, I would. We haven't had a bad candidate from them yet, and that's almost unheard of in the industry. Newsoft's team are engineers first. They really understand how to build and grow engineering teams. That's really the reason why we decided to work with them.”"
    ],
}

export const HELLO_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-106659",
    points: [
        {
            mark: "4.5",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}