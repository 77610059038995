import React from "react"
import LazyImage from "../../components/LazyImage"
import AppLink from "../../components/AppLink"
import { SOCIAL_MEDIA } from "../../constants/links"
import { MAIN_SECTION } from "../../constants/common"

import "./index.scss"

export const BookACall = () => {
    return (
        <div className="book-wrapper" id={MAIN_SECTION.BOOK_A_CALL}>
            <div className="book-call">
                <div className="book-call-header">
                    <h1 className="t-h-24">
                        Arrange a meeting with <br/>
                        CEO & Co-Founder at Newsoft
                    </h1>
                    <AppLink className="base-link-upper book-desktop clickable-link-white-background" href={SOCIAL_MEDIA.VolodymyrTkachCalendy} >
                        Book a call 
                    </AppLink>
                </div>
                <div className="book-call-info">
                    <LazyImage
                        alt="Volodymyr Tkach - CEO & Co-Founder at Newsoft"
                        src="https://newsoft-ns.com/media/home/Call_to_action.webp"
                    />
                    <h2 className="t-s1-m">Volodymyr Tkach</h2>
                    <hr />
                    <h3 className="t-s-14">CEO & Co-Founder at Newsoft</h3>
                    <AppLink className="base-link clickable-link-white-background" href={SOCIAL_MEDIA.VolodymyrTkachLinkedin}>
                        LinkedIn
                    </AppLink>
                </div>
                <AppLink className="base-link-upper book-mobile" href={SOCIAL_MEDIA.VolodymyrTkachCalendy}>
                     Book a call
                </AppLink>
            </div>
        </div>
    )
}
