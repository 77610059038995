import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga"
import "./index.scss"

const BlankLayout = ({ children }) => {
    const location = useLocation()

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    },[location.pathname])

    return (
        <div className="blank-layout">
            <div className="blank-layout-content">
                {children}
            </div>
        </div>
    )
}

export default BlankLayout
