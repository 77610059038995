import React from "react"
import BaseIcon from "./BaseIcon"

const ApiIntegrationIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M50 5.5H10C7.23858 5.5 5 7.73858 5 10.5V20.5C5 23.2614 7.23858 25.5 10 25.5H50C52.7614 25.5 55 23.2614 55 20.5V10.5C55 7.73858 52.7614 5.5 50 5.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M50 35.5H10C7.23858 35.5 5 37.7386 5 40.5V50.5C5 53.2614 7.23858 55.5 10 55.5H50C52.7614 55.5 55 53.2614 55 50.5V40.5C55 37.7386 52.7614 35.5 50 35.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 15.5H15.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 45.5H15.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default ApiIntegrationIcon
