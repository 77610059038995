import React from "react"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import { ServiceHeader } from "../../features/service-header"
import {
    IT_STAFF_AUGMENTATION_CASES,
    IT_STAFF_AUGMENTATION_CLIENTS,
    IT_STAFF_AUGMENTATION_HEADER,
    IT_STAFF_AUGMENTATION_PROCESS,
    IT_STAFF_AUGMENTATION_SERVICES,
    IT_STAFF_AUGMENTATION_WHY
} from "../../data/services/it_staff_augmentation"
import { ServiceServices } from "../../features/service-services"
import BigNumList from "../../features/big-num-list"
import RoundedList from "../../features/rounded-list"
import { OurCases } from "../../features/our-cases"
import { OUR_CASES } from "../../data/home/our-cases"
import { OurTestimonials } from "../../features/our-testimonials"
import { OUR_TESTIMONIALS } from "../../data/home/our-testimonials"
import ServiceTemplate from "../../templates/service"


const ITStaffAugmentation = () => {
    return (
        <>
            <Helmet defer={false}>
                <title>IT Staff Augmentation - Newsoft</title>
            </Helmet>
            <ServiceTemplate>
                <ServiceHeader 
                    header={IT_STAFF_AUGMENTATION_HEADER.header}
                    desc={IT_STAFF_AUGMENTATION_HEADER.desc}
                    image={IT_STAFF_AUGMENTATION_HEADER.image}/>

                <ServiceServices
                    header={IT_STAFF_AUGMENTATION_SERVICES.header}
                    services={IT_STAFF_AUGMENTATION_SERVICES.services}/>

                <BigNumList
                    header="Development process"
                    list={IT_STAFF_AUGMENTATION_PROCESS}
                    className="service-template-big-num-list"
                    mode={"light"}/>

                <RoundedList className={"service-template-why"} header="Why Newsoft?" list={IT_STAFF_AUGMENTATION_WHY} />
                
                <OurCases size={3} cases={OUR_CASES.filter(o => IT_STAFF_AUGMENTATION_CASES.includes(o.header))}/>
                
                <OurTestimonials testimonials={OUR_TESTIMONIALS.filter(o => IT_STAFF_AUGMENTATION_CLIENTS.includes(o.company))}/>
            </ServiceTemplate>
            <BookACall/>
        </>
    )
}

export default ITStaffAugmentation
