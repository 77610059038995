import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"
import NextJSLogo from "../../assets/images/technologies/nextjs.svg"
import NodeJSLogo from "../../assets/images/technologies/nodejs.svg"
import KoaLogo from "../../assets/images/technologies/koa.svg"
import RecoilLogo from "../../assets/images/technologies/recoil.svg"
import StripeLogo from "../../assets/images/technologies/stripe.svg"
import ReactQueryLogo from "../../assets/images/technologies/reactQuery.svg"
import AWSCognitoLogo from "../../assets/images/technologies/awsCognito.svg"
import AWSElasticBeanstalkLogo from "../../assets/images/technologies/awsElasticBeanstalk.svg"
import AWSAmplifyLogo from "../../assets/images/technologies/awsAmplify.svg"
import SanityLogo from "../../assets/images/technologies/sanity.svg"
import GraphQLLogo from "../../assets/images/technologies/graphQL.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    EVOLVEYOU_CHALLENGES, EVOLVEYOU_CLIENT_FEEDBACK, EVOLVEYOU_HEADER,
    EVOLVEYOU_PROJECT_OVERVIEW, EVOLVEYOU_RATINGS, EVOLVEYOU_SOLUTIONS
} from "../../data/cases/evolveyou"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const EvolveYou = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>EvolveYou - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template evolveyou">
                <CaseHeader header={EVOLVEYOU_HEADER.header} desc={EVOLVEYOU_HEADER.desc} duration={EVOLVEYOU_HEADER.duration}
                    image={EVOLVEYOU_HEADER.image} industry={EVOLVEYOU_HEADER.industry} service={EVOLVEYOU_HEADER.service} location={EVOLVEYOU_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={EVOLVEYOU_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={EVOLVEYOU_SOLUTIONS} challenges={EVOLVEYOU_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={NextJSLogo} title="Next.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={NodeJSLogo} title="Node.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={KoaLogo} title="Koa.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={RecoilLogo} title="Recoil"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={StripeLogo} title="Stripe"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReactQueryLogo} title="React Query"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSCognitoLogo} title="AWS Cognito"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSElasticBeanstalkLogo} title="AWS Elastic Beanstalk"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSAmplifyLogo} title="AWS Amplify"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SanityLogo} title="Sanity"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GraphQLLogo} title="GraphQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={EVOLVEYOU_CLIENT_FEEDBACK.text} company={EVOLVEYOU_CLIENT_FEEDBACK.company} image={EVOLVEYOU_CLIENT_FEEDBACK.image}
                    linkedin={EVOLVEYOU_CLIENT_FEEDBACK.linkedin} name={EVOLVEYOU_CLIENT_FEEDBACK.name} desc={EVOLVEYOU_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={EVOLVEYOU_RATINGS.clutch} mark={EVOLVEYOU_RATINGS.mark} points={EVOLVEYOU_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.EVOLVEYOU} />
            <BookACall/>
        </>
    )
}

export default EvolveYou
