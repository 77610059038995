import React from "react"
import LazyImage from "../../components/LazyImage"

import "./index.scss"


export const IndustryHeader = ({header, image }) => {

    return (
        <div className="industry-template-header">
            <LazyImage alt={"industry card image"} src={image} className="industry-template-header-image" />
            <div className="industry-template-header-content">
                <p className="t-s-12">Check our expertise in</p>
                <h2 className="t-h-24">{header}</h2>
            </div>
        </div>
    )
}
