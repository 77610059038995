import React, {useEffect} from "react"
import { Helmet } from "react-helmet-async"
import GridLayout from "../../layouts/GridLayout"
import { BookACall } from "../../features/book-a-call"
import { ArrowGetInTouch } from "../../components/icons/Arrow"

import "./index.scss"
import { OUR_SERVICES } from "../../data/services/our-services"
import LazyImage from "../../components/LazyImage"
import { GetInTouch, handleCardClick } from "../../utils"
import { useNavigate } from "react-router-dom"


const Services = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const navigate = useNavigate()

    return (
        <>
            <Helmet defer={false}>
                <title>Services - Newsoft</title>
            </Helmet>
            <GridLayout>
                <div className="services">
                    <div className="services-header">
                        <h2 className="t-h1-m">Our services</h2>
                        <p className="services-header-desc t-s-14">At Newsoft, we are dedicated to transforming your business ideas into reality through our comprehensive range of IT services. Specializing in custom software development and staff augmentation, we provide tailor-made solutions that align with your unique needs and objectives.</p>
                        <div className="services-header-cta clickable-link-black-background" onClick={() => GetInTouch()}>
                            <a className="t-s-14">book a call</a>
                            <ArrowGetInTouch width={18} color={"#B7B7B7"} />
                        </div>
                    </div>

                    <div className="services-list">
                        {OUR_SERVICES.map((el, id) => (
                            <div key={id} className="services-list-card" onClick={(e) => handleCardClick(e, el.link)}>
                                <LazyImage alt={"service card image"} src={el.image} className="services-list-card-image" />
                                <div className="services-list-card-content">
                                    <div className="services-list-card-content-info">
                                        <div className="services-list-card-content-info-header">
                                            <h2 className="t-h-m-20">{el.header}</h2>
                                            <p className="t-s-14">{el.description}</p>
                                        </div>
                                        <div className="services-list-card-content-info-topics">
                                            {el.topics.map((topic, id) => (
                                                <p className="t-s-14" key={id}>{topic}</p>
                                            ))}

                                        </div>
                                    </div>
                                    <div className="services-list-card-content-link clickable-link-black-background" onClick={() => navigate(el.link)}>
                                        <p className="t-s-18">learn more</p>
                                        <ArrowGetInTouch width={24} color={"#B7B7B7"} />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </GridLayout>
            <BookACall/>
        </>
    )
}

export default Services
