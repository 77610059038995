export const FAQ_CONTENT = [
    {
        question: "How do you ensure the best quality of your software?",
        answer: "To provide our clients with high-quality software, we combine several strategies. We assign an experienced team to the project, carry out rigorous testing, follow Agile development practices, conduct code reviews, maintain open communication with the client, and more. We prioritize understanding of the client's objectives and tailor our solution to meet your needs following the client-centric approach.",
    },
    {
        question: "Do you offer maintenance and support services after project completion?",
        answer: "As our goal is to ensure the reliability, efficiency, and security of the software, we offer maintenance and support services after project completion. From ongoing support to customization and scaling, we're ready to help our clients develop their business.",
    },
    {
        question: "Why is your company the best partner for my business needs?",
        answer: "It is crucial for the business to select the best match for their business development. We believe we're the best match as we pride ourselves in delivering tailor-made solutions that are aligned with the business objectives, our team possesses diverse experience across multiple industries, we're committed to the constant improvement and innovation, our experience is mirrored in our achievements (Top Shopify Company Ukraine 2023, Top E-Commerce Developers Lviv 2024, Top Mobile App Developers Lviv 2021, Top B2B Company Ukraine 2020, and so on), we establish and maintain open communication with our clients, and we offer ongoing support and maintenance services once the project is deployed.",
    },
    {
        question: "Do you have an office?",
        answer: "Yes, we have a spacious office in the IT center of Ukraine, Lviv. In our office, all workers are provided with all sort of necessary equipment.  Moreover, we have additional facilities such as sport rooms, library, dining area, and many more to help our developers recharge and relax. Additionally, we have sources of back up electricity and Internet to guarantee uninterrupted work for our team.",
    },
    {
        question: "What types of pricing do you offer?",
        answer: "To evaluate the price of our services, we follow the Time and Material model. As the software development process demands flexibility from all parties involved, we ensure that our clients are billed based on actual time and resources dedicated to the project. Flexible agreement and monthly billing options offer convenience and transparency for both sides.",
    },
    {
        question: "What levels of developers will work on my project?",
        answer: "At Newsoft, we provide developers at all levels of seniority, from Juniors to Tech Leads, ensuring a perfect match for your project's needs. Our skilled and experienced professionals are carefully selected based on your specific requirements, guaranteeing the highest quality and success for your product.",
    },
]