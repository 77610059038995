import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"
import GolangLogo from "../../assets/images/technologies/golang.svg"
import GraphQLLogo from "../../assets/images/technologies/graphQL.svg"
import gRPCLogo from "../../assets/images/technologies/gRPC.svg"
import ShopifyLogo from "../../assets/images/technologies/shopify.svg"
import ECommerceLogo from "../../assets/images/technologies/e-commerce.svg"
import BigCommerceLogo from "../../assets/images/technologies/bigCommerce.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    PACKSMITH_CHALLENGES, PACKSMITH_CLIENT_FEEDBACK, PACKSMITH_HEADER,
    PACKSMITH_PROJECT_OVERVIEW, PACKSMITH_RATINGS, PACKSMITH_SOLUTIONS
} from "../../data/cases/packsmith"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Packsmith = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>Packsmith - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template packsmith">
                <CaseHeader header={PACKSMITH_HEADER.header} desc={PACKSMITH_HEADER.desc} duration={PACKSMITH_HEADER.duration}
                    image={PACKSMITH_HEADER.image} industry={PACKSMITH_HEADER.industry} service={PACKSMITH_HEADER.service} location={PACKSMITH_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={PACKSMITH_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={PACKSMITH_SOLUTIONS} challenges={PACKSMITH_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={GolangLogo} title="Golang"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GraphQLLogo} title="GraphQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={gRPCLogo} title="gRPC"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ShopifyLogo} title="Shopify"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ECommerceLogo} title="E-Commerce"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={BigCommerceLogo} title="BigCommerce"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={PACKSMITH_CLIENT_FEEDBACK.text} company={PACKSMITH_CLIENT_FEEDBACK.company} image={PACKSMITH_CLIENT_FEEDBACK.image}
                    linkedin={PACKSMITH_CLIENT_FEEDBACK.linkedin} name={PACKSMITH_CLIENT_FEEDBACK.name} desc={PACKSMITH_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={PACKSMITH_RATINGS.clutch} mark={PACKSMITH_RATINGS.mark} points={PACKSMITH_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.PACKSMITH} />
            <BookACall/>
        </>
    )
}

export default Packsmith
