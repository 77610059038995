export const CUSTOM_SOFTWARE_DEVELOPMENT_HEADER = {
    header: "Custom Software Development",
    desc: "Guided by the client-centric approach, we analyze the project's requirements and pain points. Subsequently, the Newsoft's team of experts provides a client with a customized solution crafted to propel the business forward.",
    image: "https://newsoft-ns.com/media/services/custom_software_development_main.webp"
}

export const CUSTOM_SOFTWARE_DEVELOPMENT_SERVICES = {
    header: "Custom Software Development Services",
    services: [
        {   
            header: "Web Development",
            desc: "We build responsive and scalable web applications tailored to your business needs, establishing a seamless user experience with powerful back-end functionality."
        },
        {   
            header: "Mobile App Development",
            desc: "We develop intuitive and powerful mobile applications for iOS and Android, ensuring an engaging user experience and driving business growth."
        },
        {   
            header: "Back-End Development",
            desc: "We create custom server-side solutions and build scalable architecture using  AWS services."
        },
        {   
            header: "Cross-Platform Development",
            desc: "We create versatile applications that work smoothly across multiple platforms. To maximize reach and efficiency, we use technologies such as React and Flutter."
        },
    ]
}

export const CUSTOM_SOFTWARE_DEVELOPMENT_PROCESS = [
    {
        number: "01",
        header: "Consultation",
        text: "We set up meetings to identify your business needs, goals, and project requirements.",
    },
    {
        number: "02",
        header: "Planning",
        text: "We develop a detailed project plan to ensure a clear roadmap.",
    },
    {
        number: "03",
        header: "Design",
        text: "Our team creates user-friendly and visually appealing designs according to your objectives and brand.",
    },
    {
        number: "04",
        header: "Development & Testing",
        text: "Our team develops the software using best practices and carries out rigorous testing to ensure robust functionality and performance.",
    },
    {
        number: "05",
        header: "Deployment",
        text: "Our team launches your software and provides ongoing support to ensure error-free operation and constant improvement.",
    },
]

export const CUSTOM_SOFTWARE_DEVELOPMENT_WHY = [
    "Team of experts",
    "Customized solutions",
    "Client-centric approach",
    "Flexible engagement models",
    "Transparency & Trust"
]

export const CUSTOM_SOFTWARE_DEVELOPMENT_CASES = [
    "Bazo", "TryNow", "Craft"
]

export const CUSTOM_SOFTWARE_DEVELOPMENT_CLIENTS = [
    "Bazo", "TryNow", "QuarkWorks"
]