import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperButtonNext } from "../../components/swiper-buttons/ButtonNext"
import { SwiperButtonPrevious } from "../../components/swiper-buttons/ButtonPrevious"
import { OneCase } from "../one-case"
import { OUR_CASES } from "../../data/home/our-cases"
import { ArrowGetInTouch } from "../../components/icons/Arrow"

import "swiper/css"
import "./index.scss"
import AppLink from "../../components/AppLink"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Pagination } from "swiper"
import { useMediaQuery } from "react-responsive"

export const OtherCases = ({ currentCase }) => {
    const [cardNumber, setCardNumber] = useState(1)
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    const handleSwipe = (swiper) => {
        if (swiper.swipeDirection === "prev") {
            setCardNumber(cardNumber - 1)
        } else if (swiper.swipeDirection === "next") {
            setCardNumber(cardNumber + 1)
        }
    }

    return (
        <div className="other-cases">
            <div className="other-cases-header">
                <h3 className="t-s3">More cases</h3>
                <AppLink to={NAVIGATION_LINKS.CASES} className="cta-link clickable-link-black-background">
                    see all <ArrowGetInTouch color="#B7B7B7" />
                </AppLink>
            </div>
            <Swiper spaceBetween={16} slidesPerView={isDesktop ? 1 : 1.05} allowTouchMove={true} simulateTouch={true} noSwiping={false}
                onSlideChange={(swipe) => handleSwipe(swipe)} pagination={{clickable: true}} modules={[Pagination]}
                onActiveIndexChange={(swiper) => {
                    setCardNumber(swiper.activeIndex+1)
                }}>

                {OUR_CASES.filter((el) => el.link != currentCase)
                    .map((el, id) => (
                        <SwiperSlide key={id}>
                            <OneCase
                                index={0}
                                header={el.header}
                                image={el.image}
                                desc={el.desc}
                                link={el.link}
                                industry={el.industry}
                                duration={el.duration}
                                location={el.location}
                                service={el.service}
                                color={el.color}
                            />
                        </SwiperSlide>
                    ))}
                {isDesktop && cardNumber <
                    OUR_CASES.length - 1 && (
                    <SwiperButtonNext
                        className={"swiper-button-next"}
                        setCardNumber={setCardNumber}
                        cardNumber={cardNumber}
                    />
                )}
                {isDesktop && cardNumber > 1 && (
                    <SwiperButtonPrevious
                        className={"swiper-button-previous"}
                        setCardNumber={setCardNumber}
                        cardNumber={cardNumber}
                    />
                )}
            </Swiper>
        </div>
    )
}
