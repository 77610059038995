//Social media
export const SOCIAL_MEDIA = {
    LinkedIn: "https://www.linkedin.com/company/newsoft-ns/",
    Instagram: "https://www.instagram.com/newsoft_official/",
    Facebook: "https://www.facebook.com/newsoftns",
    Clutch: "https://clutch.co/profile/newsoft#highlights",
    Medium: "https://medium.com/newsoft-official",
    Manifest: "https://themanifest.com/company/newsoft",
    YouTube: "https://www.youtube.com/@newsoft_official",
    VolodymyrTkachLinkedin: "https://www.linkedin.com/in/vova-tkach/",
    VolodymyrTkachCalendy:
        "https://calendly.com/vova_newsoft/consulting/",
    SupportEmail: "mailto:support@newsoft-ns.com",
    Location: "https://maps.app.goo.gl/dU4ykDRMTavMiKECA",
    SalesEmail: "mailto:sales@newsoft-ns.com",
}
