import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"

const LazyImage = ({
    visibleByDefault = true,
    threshold = 300,
    alt,
    src,
    className,
    style,
    id,
}) => {
    return (
        <LazyLoadImage
            alt={alt}
            id={id}
            effect="blur"
            src={src}
            className={className}
            style={style}
            threshold={threshold}
            visibleByDefault={visibleByDefault}
        />
    )
}

export default LazyImage
