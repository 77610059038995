import { NAVIGATION_LINKS } from "../../constants/common"

export const HEADER_NAVIGATION = [
    {
        header: "Services",
        link: NAVIGATION_LINKS.SERVISES,
        items: [
            {
                title: "IT Staff Augmentation",
                text: "Taking a complex approach, we offer expanding the client's team with our skilled engineers. We strive to harmoniously blend talents and expertise for successful outcomes.",
                link: NAVIGATION_LINKS.IT_STAFF_AUGMENTATION,
            },
            {
                title: "Custom Software Development",
                text: "Guided by the client-centric approach, we analyze the project's requirements and pain points. Subsequently, the Newsoft's team of experts provides a client with a customized solution crafted to propel the business forward.",
                link: NAVIGATION_LINKS.CUSTOM_SOFTWARE_DEVELOPMENT,
            }
        ],
    },
    {
        header: "Cases",
        link: NAVIGATION_LINKS.CASES,
    },
    {
        header: "Industries",
        link: NAVIGATION_LINKS.INDUSTRIES,
        items: [
            {
                title: "E-commerce",
                text: "At Newsoft, we pride ourselves on being the ideal partner for your e-commerce project. With a lot of experience and a team of experts, we're well-equipped to meet and exceed your expectations.",
                link: NAVIGATION_LINKS.E_COMMERCE,
            },
            {
                title: "Sport & Wellness",
                text: "At Newsoft, we're your ultimate partner in the realm of sport and wellness. Backed by extensive experience and a team of dedicated experts, we offer proficiency to cater to your sport and wellness needs.",
                link: NAVIGATION_LINKS.SPORT_AND_WELLNESS,
            }
        ],
    },
    {
        header: "About us",
        link: NAVIGATION_LINKS.ABOUT_US,
    },
    {
        header: "Contact us",
        link: NAVIGATION_LINKS.CONTACT_US,
    }
]
