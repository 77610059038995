import React, {useEffect} from "react"
import "./index.scss"

const CaseTemplate = ({children, className}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={`case-template ${className ? className : ""}`}>
            {children}
        </div>  
    )
}

export default CaseTemplate
