import React from "react"
import Clutch2019Icon from "../../assets/images/awards/Clutch2019.svg"
import Clutch2020Icon from "../../assets/images/awards/Clutch2020.svg"
import Clutch2021Icon from "../../assets/images/awards/Clutch2021.svg"
import Clutch2023Icon from "../../assets/images/awards/Clutch2023.svg"
import Clutch2024ShopifyIcon from "../../assets/images/awards/Clutch2024Shopify.svg"
import Clutch2024GamblingIcon from "../../assets/images/awards/Clutch2024Gambling.svg"
import Clutch2024Icon from "../../assets/images/awards/Clutch2024.svg"
import ManifestAugmentationIcon from "../../assets/images/awards/ManifestAugmentation.svg"
import ManifestECommerceIcon from "../../assets/images/awards/ManifestECommerce.svg"
import { DESKTOP_WIDTH } from "../../constants/common"
import { useMediaQuery } from "react-responsive"

import "./index.scss"
import LinkIcon from "../../components/icons/Link"
import { SOCIAL_MEDIA } from "../../constants/links"


export const AwardsBlock = () => {
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <div className="awards">
            <div className="awards-header">
                <h1 className="t-h-m-22">Newsoft awards</h1>
            </div>
            <div className="awards-content">
                <div className="awards-content-clutch">
                    <div className="awards-content-clutch-title">
                        <h2 className="t-s-16">Clutch</h2>
                        <a href={SOCIAL_MEDIA.Clutch} target="_blank" rel="preload, noopener noreferrer nofollow"><LinkIcon /></a>
                    </div>

                    <div className="awards-content-clutch-list">
                        <img src={Clutch2019Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2020Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2021Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2023Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2024GamblingIcon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2024Icon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={Clutch2024ShopifyIcon} width={isDesktop ? "129px" : "74px"} height={isDesktop ? "140px" : "80px"}/>
                    </div>

                </div>

                <div className="awards-content-manifest">
                    <div className="awards-content-manifest-title">
                        <h2 className="t-s-16"> The Manifest</h2>
                        <a href={SOCIAL_MEDIA.Manifest} target="_blank" rel="preload, noopener noreferrer nofollow"><LinkIcon /></a>
                    </div>

                    <div className="awards-content-manifest-list">
                        <img src={ManifestECommerceIcon} width={isDesktop ? "96px" : "55px"} height={isDesktop ? "140px" : "80px"}/>
                        <img src={ManifestAugmentationIcon} width={isDesktop ? "96px" : "55px"} height={isDesktop ? "140px" : "80px"}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
