import React, {useEffect, useState, useRef} from "react"
import "./index.scss"
import { OUR_HISTORY_DATA } from "../../data/about-us/our-history"
import { DESKTOP_WIDTH, MAIN_SECTION } from "../../constants/common"
import { useMediaQuery } from "react-responsive"
import { ArrowGetInTouch, ArrowCollapse} from "../../components/icons/Arrow"


const OurHistory = () => {
    const [progress, setProgress] = useState(0)
    const [currentIndex, setCurrentIndex] = useState(0)
    const sequenceRef = useRef(null)
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })
    const [showMore, setShowMore] = useState(false)
    const [visibleItems, setVisibleItems] = useState(1)

    const scrollHandler = () => {
        if (!sequenceRef.current) return

        const totalSections = OUR_HISTORY_DATA.length
        const sequenceHeight = sequenceRef.current.offsetHeight
        const sequenceTop = sequenceRef.current.offsetTop
        const scrollY = window?.scrollY

        if (!isDesktop) {
            const screenHeight = window.innerHeight
            const progressHeight = sequenceHeight - screenHeight / 2
            const currentProgress = Math.min(
                (scrollY - sequenceTop+150) / progressHeight,
                1
            ) * 100

            setProgress(currentProgress)
        } else {
            const currentProgress = (((scrollY - sequenceTop) / (totalSections * sequenceHeight)) * 800)

            if (currentProgress > 0) {
                const newIndex = Math.floor(currentProgress / 10)
                setCurrentIndex(newIndex)
            }
            setProgress(currentProgress)
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler)

        return () => {
            window.removeEventListener("scroll", scrollHandler)
        }
    }, [])

    const handleState = (index, min=0, max=50) => {
        if (isDesktop) {
            if (index < max && index >= min) return "current"
            else if (index > max-1) return "past"
            else return ""
        }
        else return "current"
    }

    return (
        <section className="our-history" id={MAIN_SECTION.ABOUT_US_HISTORY}>
            <div className="sequence on-screen" ref={sequenceRef} data-scroll-reveal-threshold="0.02" data-renderer-position="fixed" data-live="true" data-in-viewport="true" data-scroll-direction="down">
                <div className="viewport">
                    <div className="container">
                        <h1 className="our-history-title t-s3">Our History</h1>
                        {isDesktop ? 
                            <div className="details">
                                {OUR_HISTORY_DATA.map((el, id) => (
                                    <div key={id} className="item" data-index={id} data-state={handleState(currentIndex, el.minHeight, el.maxHeight)}>
                                        <div className="img">
                                            <img src={el.image} alt="Our history photo"></img>
                                        </div>
                                        <div className="description">
                                            <h2 className="scroll-reveal t-h-m-20" data-revealed="true">
                                                {el.extraHeader ? el.extraHeader : el.year}
                                            </h2>
                                            <div className="description-list scroll-reveal" data-revealed="true">
                                                {el.text.map((item, i) => (
                                                    <div key={i} className="description-list-point t-s-14">
                                                        <p>― </p>
                                                        <p className="t-s-14">{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div> : 
                            <div className="our-history-mobile">
                                {OUR_HISTORY_DATA.slice(0, visibleItems).map((el, id) => (
                                    <div key={id} className="our-history-mobile-item" data-index={id} data-state={handleState(currentIndex, el.minHeight, el.maxHeight)}>
                                        <div className="our-history-mobile-item-img">
                                            <img src={el.image} alt={el.imageAlt ? el.imageAlt : "Our history photo"}></img>
                                        </div>
                                        <div className="our-history-mobile-item-desc">
                                            <h2 className="t-h-m-20" data-revealed="true">
                                                {el.extraHeader ? el.extraHeader : el.year}
                                            </h2>
                                            <div className="our-history-mobile-item-desc-list t-b4" data-revealed="true">
                                                {el.text.map((item, i) => (
                                                    <div key={i} className="our-history-mobile-item-desc-list-point t-s-14">
                                                        <p>― </p>
                                                        <p className="t-s-14">{item}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {visibleItems === 1 && !showMore && (
                                            <h6 className="our-history-nav t-s-14 clickable-link-white-background" onClick={() => {setShowMore(true); setVisibleItems(OUR_HISTORY_DATA.length)}}>
              LEARN MORE <ArrowGetInTouch width={18} color={"#B7B7B7"} />
                                            </h6>)}
                                        {id === OUR_HISTORY_DATA.length-1 && showMore && (
                                            <h6 className="our-history-nav t-s-14 clickable-link-white-background" onClick={() => {setShowMore(false); setVisibleItems(1)}}>
              COLLAPSE <ArrowCollapse width={18} color={"#B7B7B7"} />
                                            </h6>
                                        )}
                                    </div>
                                ))}
                            </div>}

                        {isDesktop && <div className="steps">
                            <div className="progress-bar">
                                <div className="progress-bar-bg"></div>
                                <div className="progress-bar-inner">
                                    <div className="progress-bar-progress" style={{height: `${progress}%`}}></div>
                                </div>
                            </div>

                            <div className="items">
                                {OUR_HISTORY_DATA.map((el, id) => (
                                    <div key={id} className={`item text-${id%2==0 ? "right" : "left"}`} data-index={id} data-state={handleState(currentIndex, el.minHeight, el.maxHeight)}>
                                        <div className="dot"></div>
                                        <div className="icon-ring" style={{backgroundImage: `url(${el.image})`}}></div>
                                        <span className="label">
                                            <span className="label-text t-s3">{el.year}</span>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>

                </div>
            </div>
        </section>
    )
}

export default OurHistory
