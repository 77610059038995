import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"
import FlutterLogo from "../../assets/images/technologies/flutter.svg"
import FirebaseLogo from "../../assets/images/technologies/firebase.svg"
import ArcGIS from "../../assets/images/technologies/arcgis.svg"
import GoogleAuthorization from "../../assets/images/technologies/googleAuthorization.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    PEDNET_CHALLENGES, PEDNET_CLIENT_FEEDBACK, PEDNET_HEADER,
    PEDNET_PROJECT_OVERVIEW, PEDNET_RATINGS, PEDNET_SOLUTIONS
} from "../../data/cases/pednet"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Pednet = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>PedNet - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template pednet">
                <CaseHeader header={PEDNET_HEADER.header} desc={PEDNET_HEADER.desc} duration={PEDNET_HEADER.duration}
                    image={PEDNET_HEADER.image} industry={PEDNET_HEADER.industry} service={PEDNET_HEADER.service} location={PEDNET_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={PEDNET_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={PEDNET_SOLUTIONS} challenges={PEDNET_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={FlutterLogo} title="Flutter"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={FirebaseLogo} title="Firebase"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ArcGIS} title="ArcGIS"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GoogleAuthorization} title="Goggle SSO"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={PEDNET_CLIENT_FEEDBACK.text} company={PEDNET_CLIENT_FEEDBACK.company} image={PEDNET_CLIENT_FEEDBACK.image}
                    linkedin={PEDNET_CLIENT_FEEDBACK.linkedin} name={PEDNET_CLIENT_FEEDBACK.name} desc={PEDNET_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={PEDNET_RATINGS.clutch} mark={PEDNET_RATINGS.mark} points={PEDNET_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.PEDNET} />
            <BookACall/>
        </>
    )
}

export default Pednet
