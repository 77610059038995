import React from "react"
import "./index.scss"

const CaseProjectOverview = ({text}) => {
    return (
        <div className="case-template-overview">
            <h3 className="t-h-m-22">Project overview</h3>
            <div className="case-template-overview-desc">
                {text.map((el, i) => (
                    <p className="t-s-18" key={i}>{el}</p>
                ))}
            </div>
        </div>
    )
}

export default CaseProjectOverview
