import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"

import DjangoLogo from "../../assets/images/technologies/django.svg"
import PandasLogo from "../../assets/images/technologies/pandas.svg"
import SQLLogo from "../../assets/images/technologies/sql.svg"
import DatadogLogo from "../../assets/images/technologies/datadog.svg"
import TypeScriptLogo from "../../assets/images/technologies/typescript.svg"
import DockerLogo from "../../assets/images/technologies/docker.svg"
import ReactLogo from "../../assets/images/technologies/react.svg"

import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    CRAFT_CHALLENGES, CRAFT_CLIENT_FEEDBACK, CRAFT_HEADER,
    CRAFT_PROJECT_OVERVIEW, CRAFT_RATINGS, CRAFT_SOLUTIONS
} from "../../data/cases/craft"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Craft = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>Craft - Newsoft</title>
            </Helmet>
            <CaseTemplate className="case-template craft">
                <CaseHeader header={CRAFT_HEADER.header} desc={CRAFT_HEADER.desc} duration={CRAFT_HEADER.duration}
                    image={CRAFT_HEADER.image} industry={CRAFT_HEADER.industry} service={CRAFT_HEADER.service} location={CRAFT_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={CRAFT_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={CRAFT_SOLUTIONS} challenges={CRAFT_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={DjangoLogo} title="Django"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PandasLogo} title="Pandas"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SQLLogo} title="SQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DatadogLogo} title="Datadog"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={TypeScriptLogo} title="TypeScript"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DockerLogo} title="Docker"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReactLogo} title="React"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={CRAFT_CLIENT_FEEDBACK.text} company={CRAFT_CLIENT_FEEDBACK.company} image={CRAFT_CLIENT_FEEDBACK.image}
                    linkedin={CRAFT_CLIENT_FEEDBACK.linkedin} name={CRAFT_CLIENT_FEEDBACK.name} desc={CRAFT_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={CRAFT_RATINGS.clutch} mark={CRAFT_RATINGS.mark} points={CRAFT_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.CRAFT} />
            <BookACall/>
        </>
    )
}

export default Craft
