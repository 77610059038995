import React from "react"

const getSize = (size) => {
    if (size === "s") return 24
    return 24
}

const BaseIcon = ({ size = "s", width, height, children, ...props }) => (
    <svg
        width={width || getSize(size)}
        height={height || getSize(size)}
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        {children}
    </svg>
)

export default BaseIcon
