import React from "react"
import BaseIcon from "./BaseIcon"

const HeaderMenu = ({ color, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 39 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line x1="39" y1="1" y2="1" stroke={color ? color : "white"} strokeWidth="2"/>
        <line x1="39" y1="9" y2="9" stroke={color ? color : "white"} strokeWidth="2"/>
        <line x1="39" y1="17" y2="17" stroke={color ? color : "white"} strokeWidth="2"/>
    </BaseIcon>
)

export default HeaderMenu
