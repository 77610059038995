import { MAIN_SECTION } from "./constants/common"

export const GetInTouch = () => {
    const history = document.getElementById(MAIN_SECTION.ABOUT_US_HISTORY)
    if (history) {
        history.style = { "display": "none"}
    }
    const elem = document.getElementById(MAIN_SECTION.BOOK_A_CALL)
    elem.scrollIntoView({behavior: "smooth"})

    setTimeout(() => {
        if (history) {
            history.style = { "display": "block"}
        }
    }, 2000)
}

export const handleCardClick = (event, link) => {
    if (event.ctrlKey || event.metaKey) {
        window.open(link, "_blank")
    } else {
        window.location.href = link
    }
}
