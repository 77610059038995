import React from "react"
import { CaseHeader } from "../../features/case-header"
import TechnologiesList from "../../features/technologies-list"

import NodeJSLogo from "../../assets/images/technologies/nodejs.svg"
import JSLogo from "../../assets/images/technologies/js.svg"
import SendgridLogo from "../../assets/images/technologies/sendgrid.svg"
import SQLLogo from "../../assets/images/technologies/sql.svg"
import AtomLogo from "../../assets/images/technologies/atom.svg"
import PostgresLogo from "../../assets/images/technologies/postgresql.svg"
import RedisLogo from "../../assets/images/technologies/redis.svg"
import AmplitudeLogo from "../../assets/images/technologies/amplitude.svg"
import MuiLogo from "../../assets/images/technologies/mui.svg"
import ShopifyLogo from "../../assets/images/technologies/shopify.svg"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, NAVIGATION_LINKS } from "../../constants/common"
import { Helmet } from "react-helmet-async"
import { 
    BAZO_CHALLENGES, BAZO_CLIENT_FEEDBACK, BAZO_HEADER,
    BAZO_PROJECT_OVERVIEW, BAZO_RATINGS, BAZO_SOLUTIONS
} from "../../data/cases/bazo"
import CaseProjectOverview from "../../features/case-project-overview"
import CaseChallengesSolutions from "../../features/case-challenges-solutions"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import CaseClientFeedback from "../../features/case-client-feedback"
import CaseRatings from "../../features/case-ratings"
import { OtherCases } from "../../features/other-cases"
import { BookACall } from "../../features/book-a-call"
import CaseTemplate from "../../templates/case"

const Bazo = () => {
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })

    return (
        <>
            <Helmet defer={false}>
                <title>Bazo - Newsoft</title>
            </Helmet>
            <CaseTemplate className={"bazo"}>
                <CaseHeader header={BAZO_HEADER.header} desc={BAZO_HEADER.desc} duration={BAZO_HEADER.duration}
                    image={BAZO_HEADER.image} industry={BAZO_HEADER.industry} service={BAZO_HEADER.service} location={BAZO_HEADER.location}
                />
                <hr className="separator"/>
                <CaseProjectOverview text={BAZO_PROJECT_OVERVIEW} />
                <hr className="separator"/>
                <CaseChallengesSolutions solutions={BAZO_SOLUTIONS} challenges={BAZO_CHALLENGES} />
                <hr className="separator"/>
                <TechnologiesList>
                    <TechnologiesListItem logo={NodeJSLogo} title="Node.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={JSLogo} title="JavaScript"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SendgridLogo} title="SendGrid"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SQLLogo} title="SQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AtomLogo} title="Atom"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PostgresLogo} title="PostgreSQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={RedisLogo} title="Redis"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AmplitudeLogo} title="Amplitude"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={MuiLogo} title="MUI"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ShopifyLogo} title="Shopify"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                <hr className="separator"/>
                <CaseClientFeedback text={BAZO_CLIENT_FEEDBACK.text} company={BAZO_CLIENT_FEEDBACK.company} image={BAZO_CLIENT_FEEDBACK.image}
                    linkedin={BAZO_CLIENT_FEEDBACK.linkedin} name={BAZO_CLIENT_FEEDBACK.name} desc={BAZO_CLIENT_FEEDBACK.desc} />
                <hr className="separator" />
                <CaseRatings clutch={BAZO_RATINGS.clutch} mark={BAZO_RATINGS.mark} points={BAZO_RATINGS.points}/>
            </CaseTemplate>
            <OtherCases currentCase={NAVIGATION_LINKS.BAZO} />
            <BookACall/>
        </>
    )
}

export default Bazo
