import React from "react"
import { ArrowGetInTouch } from "../../components/icons/Arrow"
import { useNavigate } from "react-router-dom"

import "./index.scss"


export const IndustrySolutions = ({header, desc, solutions }) => {
    const navigate = useNavigate()

    return (
        <div className="industry-template-solutions">
            <div className="industry-template-solutions-text">
                <div className="industry-template-solutions-text-header">
                    <h2 className="t-s3">{header}</h2>
                </div>

                <div className="industry-template-solutions-text-desc">
                    <p className="t-s-14">{desc}</p>
                </div>
            </div>
            <div className="industry-template-solutions-items">
                {solutions.map((el, id) => (
                    <div className="industry-template-solutions-items-item" key={id}>
                        <div className="industry-template-solutions-items-item-info">
                            <h3 className="t-h-m-20">{el.header}</h3>
                            <p className="t-s-16">{el.text}</p>
                        </div>
                        {el.link && <div className="industry-template-solutions-items-item-link cta-link clickable-link-white-background"
                            onClick={() => navigate(el.link)}>
                            <a className="t-s-18">get in touch</a>
                            <ArrowGetInTouch width={24} color={"#757579"} />
                        </div>}
                    </div>
                ))}
            </div>
           
        </div>
    )
}
