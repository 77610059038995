import { NAVIGATION_LINKS } from "../../constants/common"

export const EVOLVEYOU_HEADER = {
    image: "https://newsoft-ns.com/media/cases/evolveyou.webp",
    imageAlt: "EvolveYou & Newsoft",
    header: "EvolveYou",
    desc: "IT Staff Augmentation for a global fitness company",
    link: NAVIGATION_LINKS.EVOLVEYOU,
    industry: {label: "Sport & Wellness", value: "sport_wellness"},
    duration: "1+ year (ongoing)",
    location: "United Kingdom",
    service: [
        {label: "IT Staff Augmentation", value: "it_staff_augmentation"},
        {label: "Mobile App Development", value: "mobile_app_development"},
        {label: "Web Development", value: "web_development"}
    ],
    color: "#1A7B85"
}

export const EVOLVEYOU_PROJECT_OVERVIEW = [
    "EvolveYou is a global fitness community, trusted by more than 1M women, that aims to guide its users on their fitness journey by developing a fitness application. Our team's scope included fixing critical issues with the iOS player, optimizing the forum's performance, enhancing user authentication, and ensuring a smooth transition to Stripe for payment processing. Additionally, we were responsible for implementing Single Sign-On (SSO) functionality for social media login.",
    "Team: 8 developers proficient in React Native, React, Node.js, Python, Android, and iOS."
]

export const EVOLVEYOU_CHALLENGES = [
    "Simplify and secure the user authentication process to improve user access and reduce friction",
    "Improve web-based subscription and payment flowsRedesign subscription and payment flows to increase conversion rates",
    "Stabilize and improve the performance of the iOS native video player",
    "Enhance the performance and user experience of the fitness forum",
    "Introduce new and effective ways for users to perform workouts at home",
    "Integrate an efficient content management solution to streamline content creation and updates",
    "Develop a strong foundation of robust design components",
    "Investigate ways to improve user experience within the Nutrition page"
]

export const EVOLVEYOU_SOLUTIONS = [
    "Implemented single error-free authentication using Amazon Cognito",
    "Developed new design pages to handle subscription flows",
    "Transitioned from Chargebee to Stripe for streamlined payment processing",
    "Enabled Single Sign-On (SSO) via social media platforms",
    "Improved iOS player stability by leveraging the latest iOS features",
    "Optimized database queries for faster interaction and response times",
    "Fully rebuilt five major workout flows, including design and business logic enhancements",
    "Used Strapi for advanced management of workout-related content",
    "Refactored existing components and implemented new versions in the codebase",
    "Migrated to a new CMS (Strapi) and implemented new UI/UX for improved user experience"
]

export const EVOLVEYOU_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/boris_felix.webp",
    name: "Boris Felix",
    desc: "Senior Head of Engineering",
    company: "EvolveYou App Ltd",
    linkedin: "https://www.linkedin.com/in/borisfelix/",
    text: [
        "“Newsoft has delivered an app with simplified, error-free authentication, a stable payment system, and an effortless sign-in with SSO. As a result, user engagement has improved. The team delivers items on time and communicates clearly via virtual meetings and email. Their quality services stand out. We're impressed by the great quality of their work and understanding of our technical structure and expectations.”"
    ],
}

export const EVOLVEYOU_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-248145",
    points: [
        {
            mark: "5.0",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}