import { NAVIGATION_LINKS } from "../../constants/common"

export const TRYNOW_HEADER = {
    image: "https://newsoft-ns.com/media/cases/trynow.webp",
    imageAlt: "TryNow & Newsoft",
    header: "TryNow",
    desc: "Custom Software Development for an American Try Before You Buy project",
    link: NAVIGATION_LINKS.TRYNOW,
    industry: {label: "E-Commerce", value: "e_commerce"},
    duration: "3 years (ongoing)",
    location: "USA",
    service: [
        {label: "Custom Software Development", value: "custom_software_development"},
        {label: "Shopify App Development and Integration", value: "shopify_app_development_and_integration"}
    ],
    color: "#002E25"
}

export const TRYNOW_PROJECT_OVERVIEW = [
    "TryNow is a Try Before You Buy solution for shoppers and Shopify brands. It transforms your living room into a fitting room, authorizes credit cards, tracks SKUs, and captures payments. The team was tasked to seamlessly integrate the app with Shopify checkout, streamline fulfillment and reverse logistics while minimizing fraud and non-payment risks.",
    "Team: Senior Python Developer/E-commerce expert, 2 Python Developers, and QA Engineer."
]

export const TRYNOW_CHALLENGES = [
    "Ensure the rapid response to any updates or changes in store orders",
    "Extend the application to seamlessly integrate with Shopify payment processing requirements",
    "Enhance the system's capacity to handle large volumes of orders efficiently",
    "Establish easy and reliable between microservices and the GraphQL Core API",
    "Consistently deliver high quality of the service",
    "Add the possibility to easily implement TryNow functionality for any store",
]

export const TRYNOW_SOLUTIONS = [
    "Built a robust webhook processing system using AWS Event Partner source",
    "Developed a direct Shopify integration service with Shopify using the new Deferred Purchase APIs",
    "Implemented an event-based microservices architecture",
    "Built a custom GraphQL client to simplify and enhance the development experience",
    "Established thorough manual testing and unit test coverage constantly exceeding 95%",
    "Implemented Shopify App Blocks to extend the application's functionality and support the implementation of TryNow features",
]

export const TRYNOW_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/benjamin_davis.webp",
    name: "Benjamin Davis",
    desc: "Founder & CEO",
    company: "TryNow",
    linkedin: "https://www.linkedin.com/in/benjamin-davis-1010a821/",
    text: [
        "“The Newsoft team played a significant role by proposing business solution ideas and offering expertise in Python and Shopify's APIs. They consulted with the TryNow team on various aspects of the project, including creating an easy-to-use client for an internal API and optimizing queries and other application logic.",
        "Newsoft's engineers were responsible for developing a resilient system for processing Shopify data updates and implementing a billing system. They worked around the clock to develop and test the product, with half of the day worked by the Ukrainian team and the other half by our team in the US. The project resulted in valuable outcomes for both of us.”"
    ],
}

export const TRYNOW_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-197281",
    points: [
        {
            mark: "5.0",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}