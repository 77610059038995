export const OUR_TESTIMONIALS = [
    {
        image: "https://newsoft-ns.com/media/clients/simon_robb.webp",
        name: "Simon Robb",
        desc: "Co-Founder & CTO",
        company: "Packsmith",
        clutch: "https://clutch.co/profile/newsoft#review-243557",
        linkedin: "https://www.linkedin.com/in/simon-robb-6341935b/",
        rating: "5.0",
        text: [
            "“The project with Newsoft has increased the client's engineering capacity. The team effectively plans timelines, communicates progress, and completes assigned tasks. Newsoft demonstrates teamwork and excellence and values employees' ongoing growth and well-being. They're a trustworthy team.”",
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/boris_felix.webp",
        name: "Boris Felix",
        desc: "Senior Head of Engineering",
        company: "EvolveYou App Ltd",
        clutch: "https://clutch.co/profile/newsoft#review-24814",
        linkedin: "https://www.linkedin.com/in/borisfelix/",
        rating: "5.0",
        text: [
            "“We quickly bonded and the Newsoft team was very flexible and keen to understand our needs. It is safe to say that Newsoft is a very capable and respectable company. We did not only extend our team with ease but we also gained the confidence that we would be able to beat all our deadlines, regardless of the limited in-house resources that we had at a time”."
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/brandon_younessi.webp",
        name: "Brandon Younessi",
        desc: "CEO",
        company: "Bazo",
        clutch: "https://clutch.co/profile/newsoft#review-2237224",
        linkedin: "https://www.linkedin.com/in/brandonyounessi/",
        rating: "5.0",
        text: [
            "“Newsoft has proven to be a reliable and talented partner, providing top-notch quality work as if they were part of the client's team.Thanks to their new app, the client has seen excellent results in user engagement, conversion rates, merchant satisfaction, security metrics, and inventory reduction.”"
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/bryan_pratte.webp",
        name: "Bryan Pratte",
        desc: "Co-Founder & CEO",
        company: "QuarkWorks",
        clutch: "https://clutch.co/profile/newsoft#review-106659",
        linkedin: "https://www.linkedin.com/in/bryanpratte/",
        rating: "5.0",
        text: [
            "“If I could hire more people from them for specific roles I have open, I would. We haven't had a bad candidate from them yet, and that's almost unheard of in the industry. Newsoft's team are engineers first. They really understand how to build and grow engineering teams. That's really the reason why we decided to work with them.”"
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/liza_taylor.webp",
        name: "Liza Taylor",
        desc: "Lead Talent Acquisition",
        company: "Future Platforms",
        clutch: "https://clutch.co/profile/newsoft#review-244863",
        linkedin: "https://www.linkedin.com/in/lisataylor25",
        rating: "5.0",
        text: [
            "“Newsoft's skilled workers deliver high levels of integrity and quality products on time. Newsoft has been a trustworthy team that brings professionalism, integrity, and knowledge. We have a high level of faith in them and we have built and maintained a great relationship”."
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/benjamin_davis.webp",
        name: "Benjamin Davis",
        desc: "Founder & CEO",
        company: "TryNow",
        clutch: "https://clutch.co/profile/newsoft#review-2137144",
        linkedin: "https://www.linkedin.com/in/benjamin-davis-1010a821/",
        rating: "5.0",
        text: [
            "“The Newsoft team had a successful 3-year partnership with TryNow. The Ukrainian team played a significant role by proposing business solution ideas and offering expertise in Python and Shopify's APIs. They consulted with our team on various aspects of the project, including creating an easy-to-use client for an internal API and optimizing queries and other application logic.”",
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/boris_zlatkin.webp",
        name: "Boris Zlatkin",
        desc: "CEO",
        company: "Lootr",
        clutch: "https://clutch.co/profile/newsoft?page=1#review-76291",
        linkedin: "https://www.linkedin.com/in/boris-zlatkin-47407839/",
        rating: "5.0",
        text: [
            "“I worked with the Newsoft team for about 3 years on 3 different projects. And communication, quality results, and ability to meet deadlines and estimate accurately has exceeded my expectations. If you're looking for high quality engineering with a fair pricing, the Newsoft team is your answer."
        ],
    },
    {
        image: "https://newsoft-ns.com/media/clients/jacob_muchow.webp",
        name: "Jacob Muchow",
        desc: "Founder",
        company: "Orbit",
        clutch: "https://clutch.co/profile/newsoft#review-2141587",
        linkedin: "https://www.linkedin.com/in/jacobmuchow/",
        rating: "5.0",
        text: [
            "“Newsoft delivered an MVP ahead of schedule, meeting expectations. Everything was delivered on time and within budget. Newsoft was very flexible with our budget needs and MVP goals. They were also flexible allowing us to extend a week beyond the original contract to add in a few new things.”"
        ],
    },
]
