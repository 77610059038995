import Hello from "../pages/Hello"
import Home from "../pages/Home"
import PrivacyPolicy from "../pages/Privacy"
import TryNow from "../pages/TryNow"
import Pednet from "../pages/Pednet"
import Craft from "../pages/Craft"
import Error from "../pages/Error"
import Donate from "../pages/Donate"
import BasicLayout from "../layouts/BasicLayout"
import { NAVIGATION_LINKS } from "../constants/common"
import AboutUs from "../pages/AboutUs"
import Orbit from "../pages/Orbit"
import BlankLayout from "../layouts/BlankLayout"
import EvolveYou from "../pages/Evolveyou"
import Bazo from "../pages/Bazo"
import Cases from "../pages/Cases"
import Packsmith from "../pages/Packsmith"
import Services from "../pages/Services"
import CustomSoftwareDevelopment from "../pages/CustomSoftwareDevelopment"
import ITStaffAugmentation from "../pages/ITStaffAugmentation"
import TermsAndConditions from "../pages/TermsAndConditions"
import Industries from "../pages/Industries"
import ECommerce from "../pages/E-Commerce"
import SportAndWellness from "../pages/SportAndWellness"
import ContactUs from "../pages/ContactUs"
import TryNowOld from "../pages/TryNow/redirect"


export const HomeRoute = {
    path: NAVIGATION_LINKS.HOME,
    layout: BasicLayout,
    component: Home,
}

export const CasesRoute = {
    path: NAVIGATION_LINKS.CASES,
    layout: BasicLayout,
    component: Cases,
}

export const ServicesRoute = {
    path: NAVIGATION_LINKS.SERVISES,
    layout: BasicLayout,
    component: Services,
}

export const IndustriesRoute = {
    path: NAVIGATION_LINKS.INDUSTRIES,
    layout: BasicLayout,
    component: Industries,
}

export const PrivacyRoute = {
    path: NAVIGATION_LINKS.PRIVACY_POLICY,
    layout: BasicLayout,
    component: PrivacyPolicy,
}

export const TermsAndConditionsRoute = {
    path: NAVIGATION_LINKS.TERMS_AND_CONDITIONS,
    layout: BasicLayout,
    component: TermsAndConditions,
}

export const TryNowOldRoute = {
    path: NAVIGATION_LINKS.TRYNOW_OLD,
    layout: BasicLayout,
    component: TryNowOld,
}


export const TryNowRoute = {
    path: NAVIGATION_LINKS.TRYNOW,
    layout: BasicLayout,
    component: TryNow,
}

export const PacksmithRoute = {
    path: NAVIGATION_LINKS.PACKSMITH,
    layout: BasicLayout,
    component: Packsmith,
}

export const BazoRoute = {
    path: NAVIGATION_LINKS.BAZO,
    layout: BasicLayout,
    component: Bazo,
}

export const EvolveYouRoute = {
    path: NAVIGATION_LINKS.EVOLVEYOU,
    layout: BasicLayout,
    component: EvolveYou,
}

export const HelloRoute = {
    path: NAVIGATION_LINKS.HELLO,
    layout: BasicLayout,
    component: Hello,
}

export const CraftRoute = {
    path: NAVIGATION_LINKS.CRAFT,
    layout: BasicLayout,
    component: Craft,
}

export const PednetRoute = {
    path: NAVIGATION_LINKS.PEDNET,
    layout: BasicLayout,
    component: Pednet,
}

export const AboutUsRoute = {
    path: NAVIGATION_LINKS.ABOUT_US,
    layout: BasicLayout,
    component: AboutUs,
}

export const CustomSoftwareDevelopmentRoute = {
    path: NAVIGATION_LINKS.CUSTOM_SOFTWARE_DEVELOPMENT,
    layout: BasicLayout,
    component: CustomSoftwareDevelopment,
}

export const ITStaffAugmentationRoute = {
    path: NAVIGATION_LINKS.IT_STAFF_AUGMENTATION,
    layout: BasicLayout,
    component: ITStaffAugmentation,
}

export const ECommerceRoute = {
    path: NAVIGATION_LINKS.E_COMMERCE,
    layout: BasicLayout,
    component: ECommerce,
}

export const SportAndWellnessRoute = {
    path: NAVIGATION_LINKS.SPORT_AND_WELLNESS,
    layout: BasicLayout,
    component: SportAndWellness,
}

export const OrbitRoute = {
    path: NAVIGATION_LINKS.ORBIT,
    layout: BasicLayout,
    component: Orbit,
}

export const ErrorRoute = {
    path: NAVIGATION_LINKS.ERROR,
    layout: null,
    component: Error,
}

export const DonateRoute = {
    path: NAVIGATION_LINKS.DONATE,
    layout: BlankLayout,
    component: Donate,
}

export const ContactUsRoute = {
    path: NAVIGATION_LINKS.CONTACT_US,
    layout: BasicLayout,
    component: ContactUs,
}

export const routes = [
    HomeRoute,
    CasesRoute,
    TryNowRoute,
    TryNowOldRoute,
    EvolveYouRoute,
    BazoRoute,
    PacksmithRoute,
    HelloRoute,
    OrbitRoute,
    CraftRoute,
    PednetRoute,
    AboutUsRoute,
    ServicesRoute,
    CustomSoftwareDevelopmentRoute,
    ITStaffAugmentationRoute,
    PrivacyRoute,
    TermsAndConditionsRoute,
    ECommerceRoute,
    SportAndWellnessRoute,

    IndustriesRoute,
    ContactUsRoute,

    ErrorRoute,

    DonateRoute,
]
