import React from "react"
import BaseIcon from "./BaseIcon"

export const ArrowGetInTouch = ({ color, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10 15.5L15 20.5L20 15.5" stroke={color ? color : "#27272D"} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4 4.5H11C12.0609 4.5 13.0783 4.92143 13.8284 5.67157C14.5786 6.42172 15 7.43913 15 8.5V20.5" stroke={color ? color : "#27272D"} strokeOpacity="0.7" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export const ArrowCollapse = ({ color, ...props }) => (
    <BaseIcon
        {...props}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M10 9L15 4M15 4L20 9M15 4V16C15 17.0609 14.5786 18.0783 13.8284 18.8284C13.0783 19.5786 12.0609 20 11 20H4" stroke={color ? color : "#27272D"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

