import React from "react"
import { Card } from "./subcomponents/Card"
import AppLink from "../../components/AppLink"
import { ArrowGetInTouch } from "../../components/icons/Arrow"
import "./index.scss"

export const ServicesCards = ({ scrollHeader, link, cardsArray }) => {
    return (
        <div className="services-cards">
            <div className="services-cards-header">
                <h3 className="t-s3">{scrollHeader}</h3>
                {link && <AppLink to={link} className="cta-link clickable-link-black-background">
                    details <ArrowGetInTouch color="#FFFFFF" />
                </AppLink>}
            </div>
            <div className="services-cards-list">
                {cardsArray.map((el, id) => (
                    <Card
                        key={el.header + id}
                        image={el.image}
                        imageAlt={el.imageAlt}
                        header={el.header}
                        text={el.text}
                        link={el.link}
                    />
                ))}
            </div>
        </div>
    )
}
