import React from "react"
import "./index.scss"

const TechnologiesList = ({
    children,
    className,
}) => {
    return (
        <div className={`technologies-list ${className ? className : ""}`}>
            <div className="technologies-list-icons">{children}</div>
        </div>
    )
}

export default TechnologiesList
