import React from "react"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import BigNumList from "../../features/big-num-list"
import { OurCases } from "../../features/our-cases"
import { OurTestimonials } from "../../features/our-testimonials"
import { OUR_TESTIMONIALS } from "../../data/home/our-testimonials"
import IndustryTemplate from "../../templates/industry"
import { 
    SPORT_AND_WELLNESS_CLIENTS,
    SPORT_AND_WELLNESS_CLUTCH_AWARDS,
    SPORT_AND_WELLNESS_HEADER,
    SPORT_AND_WELLNESS_MANIFEST_AWARDS,
    SPORT_AND_WELLNESS_OVERVIEW,
    SPORT_AND_WELLNESS_SERVICES,
    SPORT_AND_WELLNESS_SOLUTIONS,
    SPORT_AND_WELLNESS_WORKFLOW
} from "../../data/industries/sport_and_wellness"
import { IndustryHeader } from "../../features/industry-header"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../../constants/common"
import { IndustryOverviewAwards } from "../../features/industry-overview-and-awards"
import { IndustrySolutions } from "../../features/industry-solutions"
import { IndustryServices } from "../../features/industry-services"
import TechnologiesList from "../../features/technologies-list"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import { IndustryInto } from "../../features/industry-intro"

import CharlesProxyLogo from "../../assets/images/technologies/charlesProxy.svg"
import SwaggerLogo from "../../assets/images/technologies/swagger.svg"
import AppiumLogo from "../../assets/images/technologies/appium.svg"
import JavaLogo from "../../assets/images/technologies/java.svg"
import AndroidStudioLogo from "../../assets/images/technologies/androidStudio.svg"
import PostmanLogo from "../../assets/images/technologies/postman.svg"
import JetpackComposeLogo from "../../assets/images/technologies/jetpackCompose.svg"
import DetektLogo from "../../assets/images/technologies/detekt.svg"
import ReduxLogo from "../../assets/images/technologies/redux.svg"
import GitHubLogo from "../../assets/images/technologies/github.svg"
import FigmaLogo from "../../assets/images/technologies/figma.svg"
import XCodeLogo from "../../assets/images/technologies/xCode.svg"
import NodeJSLogo from "../../assets/images/technologies/nodejs.svg"
import MuiLogo from "../../assets/images/technologies/mui.svg"
import TypeScriptLogo from "../../assets/images/technologies/typescript.svg"
import ReactLogo from "../../assets/images/technologies/react.svg"
import NextJSLogo from "../../assets/images/technologies/nextjs.svg"
import StripeLogo from "../../assets/images/technologies/stripe.svg"
import AWSCognitoLogo from "../../assets/images/technologies/awsCognito.svg"
import AWSAmplifyLogo from "../../assets/images/technologies/awsAmplify.svg"
import GraphQLLogo from "../../assets/images/technologies/graphQL.svg"
import FirebaseLogo from "../../assets/images/technologies/firebase.svg"
import KotlinLogo from "../../assets/images/technologies/kotlin.svg"
import AndroidLogo from "../../assets/images/technologies/android.svg"
import FlutterLogo from "../../assets/images/technologies/flutter.svg"
import { E_COMMERCE_HEADER } from "../../data/industries/e-commerce"
import { EVOLVEYOU_HEADER } from "../../data/cases/evolveyou"
import { TRYNOW_HEADER } from "../../data/cases/trynow"
import { BAZO_HEADER } from "../../data/cases/bazo"


const SportAndWellness = () => {
    const isTablet = useMediaQuery({minWidth: TABLET_WIDTH})
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <>
            <Helmet defer={false}>
                <title>Sport & Wellness - Newsoft</title>
            </Helmet>
            <IndustryTemplate>
                <IndustryHeader 
                    header={SPORT_AND_WELLNESS_HEADER.header} 
                    image={isTablet ? SPORT_AND_WELLNESS_HEADER.image : SPORT_AND_WELLNESS_HEADER.mobileImage}/>

                <IndustryOverviewAwards 
                    overview={SPORT_AND_WELLNESS_OVERVIEW}
                    clutchAwards={SPORT_AND_WELLNESS_CLUTCH_AWARDS}
                    manifestAwards={SPORT_AND_WELLNESS_MANIFEST_AWARDS}/>

                <IndustrySolutions 
                    header={"E-Commerce Solutions"}
                    desc={SPORT_AND_WELLNESS_SOLUTIONS.desc}
                    solutions={SPORT_AND_WELLNESS_SOLUTIONS.solutions} />
                    
                <IndustryServices
                    header={"E-Commerce Services"} 
                    services={SPORT_AND_WELLNESS_SERVICES}/>
                <BigNumList
                    header="Our workflow"
                    list={SPORT_AND_WELLNESS_WORKFLOW}
                    className="industry-template-big-num-list"
                    mode={"light"}/>

                <TechnologiesList>
                    <TechnologiesListItem logo={FlutterLogo} title="Flutter"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={CharlesProxyLogo} title="Charles Proxy"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SwaggerLogo} title="Swagger"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AppiumLogo} title="Appium"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={JavaLogo} title="Java"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AndroidStudioLogo} title="Android Studio"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PostmanLogo} title="Postman"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={KotlinLogo} title="Kotlin"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AndroidLogo} title="Android"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={MuiLogo} title="MUI"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={JetpackComposeLogo} title="Jetpack Compose"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={FirebaseLogo} title="Firebase"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DetektLogo} title="Detekt"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReactLogo} title="React"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReduxLogo} title="Redux"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GraphQLLogo} title="GraphQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GitHubLogo} title="GitHub"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={FigmaLogo} title="Figma"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={XCodeLogo} title="xCode"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={NextJSLogo} title="Next.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={TypeScriptLogo} title="TypeScript"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSAmplifyLogo} title="AWS Amplify"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={StripeLogo} title="Stripe"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={NodeJSLogo} title="Node.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AWSCognitoLogo} title="AWS Cognito"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                
                <OurCases cases={[EVOLVEYOU_HEADER, TRYNOW_HEADER, BAZO_HEADER]}/>
                
                <OurTestimonials testimonials={OUR_TESTIMONIALS.filter(o => SPORT_AND_WELLNESS_CLIENTS.includes(o.company))}/>

                <IndustryInto key={1} industry={E_COMMERCE_HEADER}/>
            </IndustryTemplate>
            <BookACall/>
        </>
    )
}

export default SportAndWellness
