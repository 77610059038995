import React from "react"
import { ArrowGetInTouch } from "../../components/icons/Arrow"
import "./index.scss"
import { GetInTouch } from "../../utils"
import LazyImage from "../../components/LazyImage"

export const ServiceHeader = ({header, desc, image }) => {
    return (
        <div className="service-template-header">
            <h2 className="t-h1-m">{header}</h2>
            <p className="service-template-header-desc t-s-14">{desc}</p>
            <div className="service-template-header-cta clickable-link-black-background" onClick={() => GetInTouch()}>
                <a className="t-s-14">book a call</a>
                <ArrowGetInTouch width={18} color={"#B7B7B7"} />
            </div>

            <LazyImage alt={header} src={image}/>
        </div>
    )
}
