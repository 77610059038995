import React from "react"
import BaseIcon from "./BaseIcon"

const WebDevelopmentIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 18.5V50.5C5 53.2614 8.19797 55.5 12.1429 55.5H47.8571C51.802 55.5 55 53.2614 55 50.5V18.5M5 18.5V10.5C5 7.73858 8.19797 5.5 12.1429 5.5H47.8571C51.802 5.5 55 7.73858 55 10.5V18.5M5 18.5H55" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 12H12.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17 12H17.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 12H22.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default WebDevelopmentIcon
