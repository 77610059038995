import React, { useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperButtonPrevious } from "../../components/swiper-buttons/ButtonPrevious"
import { SwiperButtonNext } from "../../components/swiper-buttons/ButtonNext"
import { OneTestimonial } from "../one-testimonial"
import { OUR_TESTIMONIALS } from "../../data/home/our-testimonials"

import "swiper/css"
import "./index.scss"
import { DESKTOP_WIDTH, MONITOR_WIDTH, TABLET_WIDTH } from "../../constants/common"
import { Navigation, Pagination } from "swiper"
import { useMediaQuery } from "react-responsive"


export const OurTestimonials = ({
    testimonials = OUR_TESTIMONIALS,
}) => {
    const [testimonialNumber, setTestimonialNumber] = useState(1)
    const isMonitor = useMediaQuery({ minWidth: MONITOR_WIDTH })
    const isDesktop = useMediaQuery({ minWidth: DESKTOP_WIDTH })
    const isTablet = useMediaQuery({ minWidth: TABLET_WIDTH })

    const handleSwipe = (swiper) => {
        if (swiper.swipeDirection === "prev") {
            setTestimonialNumber(testimonialNumber - 1)
        } else if (swiper.swipeDirection === "next") {
            setTestimonialNumber(testimonialNumber + 1)
        }
    }

    return (
        <div className="our-testimonials">
            <div className="our-testimonials-header">
                <h3 className="t-s3">Our testimonials</h3>
                <div className="our-testimonials-header-controls">
                    {isDesktop && (
                        <>
                            <SwiperButtonPrevious
                                className={`swiper-button-previous testimonials-previous ${testimonialNumber === 1 ? "disabled" : ""}`}
                                swipe={false}
                                disabled={testimonialNumber === 1}
                                setTestimonialNumber={setTestimonialNumber}
                                testimonialNumber={testimonialNumber}                                
                            />
                            <SwiperButtonNext
                                className={`swiper-button-next testimonials-next ${testimonialNumber === testimonials.length ? "disabled" : ""}`}
                                swipe={false}
                                disabled={testimonialNumber === testimonials.length}
                                setTestimonialNumber={setTestimonialNumber}
                                testimonialNumber={testimonialNumber}
                            />
                        </>
                    )}
                </div>
            </div>
            <Swiper
                spaceBetween={isDesktop ? 40 : 16}
                slidesPerView={isMonitor ? 1.4 : (isDesktop ? 1.3 : (isTablet ? 1.5 : 1.05))}
                centeredSlides={true}
                centeredSlidesBounds={true}
                allowTouchMove={true}
                simulateTouch={true}
                noSwiping={false}
                onSlideChange={(swipe) => handleSwipe(swipe)}
                pagination={{ clickable: true }}
                modules={[Pagination, Navigation]}
                navigation={{
                    nextEl: ".testimonials-next",
                    prevEl: ".testimonials-previous",
                }}
            >
                {testimonials.map((el, id) => (
                    <SwiperSlide key={id}>
                        <OneTestimonial el={el} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}