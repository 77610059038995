import React from "react"
import BaseIcon from "./BaseIcon"

const CybersecurityIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30 55.5C30 55.5 50 45.5 50 30.5V13L30 5.5L10 13V30.5C10 45.5 30 55.5 30 55.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default CybersecurityIcon
