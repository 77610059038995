import React from "react"
import { Helmet } from "react-helmet-async"
import { BookACall } from "../../features/book-a-call"
import BigNumList from "../../features/big-num-list"
import { OurCases } from "../../features/our-cases"
import { OurTestimonials } from "../../features/our-testimonials"
import { OUR_TESTIMONIALS } from "../../data/home/our-testimonials"
import IndustryTemplate from "../../templates/industry"
import { 
    E_COMMERCE_CLIENTS,
    E_COMMERCE_CLUTCH_AWARDS,
    E_COMMERCE_HEADER,
    E_COMMERCE_MANIFEST_AWARDS,
    E_COMMERCE_OVERVIEW,
    E_COMMERCE_SERVICES,
    E_COMMERCE_SOLUTIONS,
    E_COMMERCE_WORKFLOW
} from "../../data/industries/e-commerce"
import { IndustryHeader } from "../../features/industry-header"
import { useMediaQuery } from "react-responsive"
import { DESKTOP_WIDTH, TABLET_WIDTH } from "../../constants/common"
import { IndustryOverviewAwards } from "../../features/industry-overview-and-awards"
import { IndustrySolutions } from "../../features/industry-solutions"
import { IndustryServices } from "../../features/industry-services"
import TechnologiesList from "../../features/technologies-list"
import TechnologiesListItem from "../../features/technologies-list/technologies-list-item"
import { IndustryInto } from "../../features/industry-intro"

import NodeJSLogo from "../../assets/images/technologies/nodejs.svg"
import MuiLogo from "../../assets/images/technologies/mui.svg"
import ReactLogo from "../../assets/images/technologies/react.svg"
import StripeLogo from "../../assets/images/technologies/stripe.svg"
import GraphQLLogo from "../../assets/images/technologies/graphQL.svg"
import JSLogo from "../../assets/images/technologies/js.svg"
import SendgridLogo from "../../assets/images/technologies/sendgrid.svg"
import SQLLogo from "../../assets/images/technologies/sql.svg"
import AtomLogo from "../../assets/images/technologies/atom.svg"
import PostgresLogo from "../../assets/images/technologies/postgresql.svg"
import RedisLogo from "../../assets/images/technologies/redis.svg"
import AmplitudeLogo from "../../assets/images/technologies/amplitude.svg"
import ShopifyLogo from "../../assets/images/technologies/shopify.svg"
import DjangoLogo from "../../assets/images/technologies/django.svg"
import AWSLogo from "../../assets/images/technologies/aws.svg"
import PythonLogo from "../../assets/images/technologies/python.svg"
import GolangLogo from "../../assets/images/technologies/golang.svg"
import ECommerceLogo from "../../assets/images/technologies/e-commerce.svg"
import BigCommerceLogo from "../../assets/images/technologies/bigCommerce.svg"
import ServerlessLogo from "../../assets/images/technologies/serverless.svg"

import { SPORT_AND_WELLNESS_HEADER } from "../../data/industries/sport_and_wellness"
import { TRYNOW_HEADER } from "../../data/cases/trynow"
import { PACKSMITH_HEADER } from "../../data/cases/packsmith"
import { BAZO_HEADER } from "../../data/cases/bazo"


const ECommerce = () => {
    const isTablet = useMediaQuery({minWidth: TABLET_WIDTH})
    const isDesktop = useMediaQuery({minWidth: DESKTOP_WIDTH})

    return (
        <>
            <Helmet defer={false}>
                <title>E-Commerce - Newsoft</title>
            </Helmet>
            <IndustryTemplate>
                <IndustryHeader 
                    header={E_COMMERCE_HEADER.header} 
                    image={isTablet ? E_COMMERCE_HEADER.image : E_COMMERCE_HEADER.mobileImage}/>

                <IndustryOverviewAwards 
                    overview={E_COMMERCE_OVERVIEW}
                    clutchAwards={E_COMMERCE_CLUTCH_AWARDS}
                    manifestAwards={E_COMMERCE_MANIFEST_AWARDS}/>

                <IndustrySolutions 
                    header={"E-Commerce Solutions"}
                    desc={E_COMMERCE_SOLUTIONS.desc}
                    solutions={E_COMMERCE_SOLUTIONS.solutions} />
                    
                <IndustryServices
                    header={"E-Commerce Services"} 
                    services={E_COMMERCE_SERVICES}/>
                <BigNumList
                    header="Our workflow"
                    list={E_COMMERCE_WORKFLOW}
                    className="industry-template-big-num-list"
                    mode={"light"}/>

                <TechnologiesList>
                    <TechnologiesListItem logo={AWSLogo} title="AWS"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ServerlessLogo} title="Serverless"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={StripeLogo} title="Stripe"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={DjangoLogo} title="Django"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AtomLogo} title="Atom"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PythonLogo} title="Python"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ShopifyLogo} title="Shopify"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={NodeJSLogo} title="Node.js"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={JSLogo} title="JavaScript"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SendgridLogo} title="SendGrid"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={SQLLogo} title="SQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={PostgresLogo} title="PostgreSQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={RedisLogo} title="Redis"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={AmplitudeLogo} title="Amplitude"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={MuiLogo} title="MUI"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ReactLogo} title="React"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GolangLogo} title="Golang"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={GraphQLLogo} title="GraphQL"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={ECommerceLogo} title="E-Commerce"
                        style={isDesktop ? { width: "50px" } : {}} />
                    <TechnologiesListItem logo={BigCommerceLogo} title="BigCommerce"
                        style={isDesktop ? { width: "50px" } : {}} />
                </TechnologiesList>
                
                <OurCases cases={[TRYNOW_HEADER, PACKSMITH_HEADER, BAZO_HEADER]}/>
                
                <OurTestimonials testimonials={OUR_TESTIMONIALS.filter(o => E_COMMERCE_CLIENTS.includes(o.company))}/>

                <IndustryInto key={1} industry={SPORT_AND_WELLNESS_HEADER}/>
            </IndustryTemplate>
            <BookACall/>
        </>
    )
}

export default ECommerce
