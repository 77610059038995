import { NAVIGATION_LINKS } from "../../constants/common"

export const OUR_SERVICES = [
    {
        image: "https://newsoft-ns.com/media/services/custom_software_development.webp",
        header: "Custom Software Development",
        description: "Guided by the client-centric approach, we analyze the project's requirements and pain points. Subsequently, the Newsoft's team of experts provides a client with a customized solution crafted to propel the business forward.",
        link: NAVIGATION_LINKS.CUSTOM_SOFTWARE_DEVELOPMENT,
        topics: [
            "Web",
            "Mobile App",
            "Back-End",
            "Cross-Platform"
        ],
    },
    {
        image: "https://newsoft-ns.com/media/services/it_staff_augmentation.webp",
        header: "IT Staff Augmentation",
        description: "Taking a complex approach, we offer expanding the client's team with our skilled engineers. We strive to harmoniously blend talents and expertise for successful outcomes.",
        link: NAVIGATION_LINKS.IT_STAFF_AUGMENTATION,
        topics: [
            "Blended Teams",
            "Dedicated Teams",
            "Managed Teams"
        ]
    }
]