import { NAVIGATION_LINKS } from "../../constants/common"

export const PEDNET_HEADER = {
    image: "https://newsoft-ns.com/media/cases/pednet.webp",
    imageAlt: "PedNet & Newsoft",
    header: "PedNet",
    desc: "custom software development for non-profit American Organization",
    link: NAVIGATION_LINKS.PEDNET,
    industry: {label: "Environmental Solutions", value: "environmental_solutions"},
    duration: "1 year",
    location: "USA",
    service: [
        {label: "Custom Software Development", value: "custom_software_development"},
        {label: "Mobile App Development", value: "mobile_app_development"},
        {label: "Cross-Platform Development", value: "cross_platform_development"},
    ],
    color: "#A58231"
}

export const PEDNET_PROJECT_OVERVIEW = [
    "Local Motion (ex-PedNet) is a grassroots advocacy organization focused on transportation equity. Its mission is to provide walking, biking, and transit solutions to meet people's everyday transportation needs. The Newsoft team was tasked with developing a mobile application for Android and iOS that allows people to interact with local authorities in the USA and report problems related to road safety, pedestrians, and cyclists. ",
    "Team: Android Developer, 2 Mobile Developers, and QA Engineer."
]

export const PEDNET_CHALLENGES = [
    "Develop a comprehensive and user-friendly mobile application from scratch",
    "Find a cost-effective way to develop back-end functionality with real-time updates",
    "Implement secure user authentication to enhance data protection and user trust",
    "Develop core functionalities to precisely meet project requirements and client expectations",
    "Design the application architecture for scalability and efficiency, ensuring future growth and optimal performance",
    "Integrate native maps for both iOS and Android platforms to provide seamless geolocation services",
    "Share business logic across Android and iOS to maintain consistency and reduce development efforts"
]

export const PEDNET_SOLUTIONS = [
    "Conducted an in-depth investigation of Flutter framework for cross-platform development",
    "Integrated Firebase Cloud Functions to enable back-end functionality",
    "Implemented secure user authentication using Firebase Authentication with Google Sign-In and OAuth 2.0 protocols",
    "Engineered and deployed core application features, ensuring full compliance with project requirements and specifications",
    "Integrated Google Maps SDK for iOS and Android, enabling native geolocation functionalities and ensuring high performance",
    "Utilized Flutter platform channels to enable native code execution"
]

export const PEDNET_CLIENT_FEEDBACK = {
    image: "https://newsoft-ns.com/media/clients/bryan_pratte.webp",
    name: "Bryan Pratte",
    desc: "Co-Founder & CEO",
    company: "QuarkWorks",
    linkedin: "https://www.linkedin.com/in/bryanpratte/",
    text: [
        "“Since Newsoft joined the project, the team has scaled and their efficiency has skyrocketed. Their knowledge combined with their friendly, efficient team makes them a reliable partner that has exceeded the client's expectations.",
        "If I could hire more people from them for specific roles I have open, I would. We haven't had a bad candidate from them yet, and that's almost unheard of in the industry. Newsoft's team are engineers first. They really understand how to build and grow engineering teams. That's really the reason why we decided to work with them.”"
    ],
}

export const PEDNET_RATINGS = {
    mark: "5.0",
    clutch: "https://clutch.co/profile/newsoft#review-106659",
    points: [
        {
            mark: "4.5",
            title: "Quality",
            subtitle: "Service & Deliverables"
        },
        {
            mark: "5.0",
            title: "Cost",
            subtitle: "Value / Within Estimates"
        },
        {
            mark: "5.0",
            title: "Schedule",
            subtitle: "On Time / Deadlines"
        },
        {
            mark: "5.0",
            title: "Willing to Refer",
            subtitle: "NPS"
        },
    ]
}