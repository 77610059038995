import React, { useState } from "react"
import "./index.scss"

export const FAQ = ({ items }) => {
    const [activeIndices, setActiveIndices] = useState([])

    const toggleFAQ = (index) => {
        if (activeIndices.includes(index)) {
            setActiveIndices(activeIndices.filter(i => i !== index))
        } else {
            setActiveIndices([...activeIndices, index])
        }
    }

    return (
        <div className="faq">
            <h2 className="t-h-m-22">FAQ</h2>
            <ul className="faq-content">
                {items.map((item, index) => (
                    <div key={index} className="faq-content-item">
                        <li className={activeIndices.includes(index) ? "active" : ""}>
                            <p className="t-h-16 question" onClick={() => toggleFAQ(index)}>
                                {item.question}
                                <div className={`arrow-toggle ${activeIndices.includes(index) ? "" : "collapsed"}`}></div>
                            </p>
                            <div className="t-s-14 answer">
                                {item.answer}
                            </div>
                        </li>
                        <hr />
                    </div>
                ))}
            </ul>
        </div>
    )
}
