export const OUR_HISTORY_DATA = [
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2019.webp",
        imageAlt: "Newsoft 2019",
        year: "2019",
        minHeight: 0,
        maxHeight: 2,
        text: [
            "Founded Newsoft company.",
            "Established the first office.",
            "Completed a travel project.",
            "Launched the Newsoft website.",
            "Successfully engaged two long-term clients.",
            "Entered the exploratory phase and formulated the company's mission."
        ]
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2020.webp",
        imageAlt: "Newsoft 2020",
        year: "2020",
        minHeight: 2,
        maxHeight: 4,
        text: [
            "Successfully engaged 5 sustainable clients.",
            "Added 5 Developers to the current team.",
            "Moved to a new and spacious office.",
            "Continue following principles: Win-Win cooperation & Creating Value."
        ]
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2021.webp",
        imageAlt: "Newsoft 2021",
        year: "2021",
        minHeight: 4,
        maxHeight: 6,
        text: [
            "Engaged 4 clients.",
            "Expanded Newsoft's workforce to a team of 20 people.",
            "Successfully navigated pandemic challenges, kept projects going.",
            "Developed the company culture, and organized team-building events, tournaments, entertainment, and trips to the Carpathians.",
            "Started working on the Newsoft Inside System."
        ]
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2022.webp",
        imageAlt: "Newsoft 2022",
        year: "2022",
        minHeight: 6,
        maxHeight: 8,
        text: [
            "Engaged 4 projects and solidified 10 long-term clients.",
            "Welcomed 13 new employees, increasing the total headcount to 25.",
            "Managed the challenges of the war and kept projects going.",
            "Released an internal Newsoft Inside System for managing employees, checking performance and improving communication between managers and employees."
        ]
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2023.webp",
        imageAlt: "Newsoft 2023",
        year: "2023",
        minHeight: 8,
        maxHeight: 10,
        text: [
            "Achieved a remarkable 53% growth.",
            "Started working with 7 new clients.",
            "Welcomed 26 new members to the Newsoft family.",
            "Drank 2,805 cups of coffee.",
            "Extended and updated our office.",
            "Updated Newsoft website.",
            "Contributed 910,100 UAH to the Ukrainian army."
        ]
    },
    {
        image: "https://newsoft-ns.com/media/about-us/our-history/2024.webp",
        imageAlt: "Newsoft 2024",
        year: "2024",
        extraHeader: "In 2024, we plan to",
        minHeight: 10,
        maxHeight: 50,
        text: [
            "Celebrate Newsoft's fifth anniversary.",
            "Engage 10 new clients.",
            "Double our revenue to mark 2024 as a year of significant growth.",
            "Welcome new employees to enhance our capabilities.",
            "Overcome the upcoming challenges and ordeals.",
            "Seize opportunities for growth and development.",
        ]
    },
]
