import React, { useState } from "react"
import CloseIcon from "../../components/icons/CloseIcon"
import { Link } from "react-router-dom"
import "./index.scss"
import { NAVIGATION_LINKS } from "../../constants/common"

const PrivacyMessage = () => {
    const [showPrivacy, setShowPrivacy] = useState(true)
    const showPrivacyMessage =
        localStorage.getItem("privacy") === "true" ? true : false

    return !showPrivacyMessage && showPrivacy ? (
        <div className="privacy-message">
            <CloseIcon onClick={() => setShowPrivacy(false)} />
            <div className="privacy-message-content">
                <div className="privacy-message-content-text">
                    <h2 className="t-s-16">We use cookie on our website</h2>
                    <p className="t-s-12">
                    We use cookies to ensure you get the best experience on our website. Our website may also collect anonymous data for analytical purposes, which helps us improve our services and adapt the content to better meet your needs. By clicking Agree you accept our
                        {" "}
                        <Link to={NAVIGATION_LINKS.PRIVACY_POLICY}>Privacy Policy</Link>
                    </p>
                </div>
                <button
                    className="t-s4"
                    onClick={() => {
                        localStorage.setItem("privacy", true)
                        setShowPrivacy(false)
                    }}
                >
                    Agree
                </button>
            </div>
        </div>
    ) : (
        <></>
    )
}

export default PrivacyMessage
