import React from "react"
import BaseIcon from "./BaseIcon"

const MobileDevelopmentIcon = ({...props }) => (
    <BaseIcon {...props} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.5 5.5H17.5C14.7386 5.5 12.5 7.73858 12.5 10.5V50.5C12.5 53.2614 14.7386 55.5 17.5 55.5H42.5C45.2614 55.5 47.5 53.2614 47.5 50.5V10.5C47.5 7.73858 45.2614 5.5 42.5 5.5Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M30 45.5H30.025" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </BaseIcon>
)

export default MobileDevelopmentIcon
