export const OUR_VALUES = [
    {
        number: "01",
        header: "Creating value",
        text: "Our guiding principle is creating valuable products which deliver a great impact on businesses and people. We work toward this goal with a commitment to quality, integrity, and innovation.",
    },
    {
        number: "02",
        header: "Win-Win cooperation",
        text: "We strive to build long-term sustainable relationships with our clients. Each of us cares deeply about the key result and future of our client's businesses.",
    },
]
